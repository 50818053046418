import cs from 'classnames';

import { flavorsMap, WineFlavorKey } from 'src/data/flavors';

interface Props {
  flavorId: WineFlavorKey;
}

export function FlavorCircle({ flavorId }: Props) {
  const flavor = flavorsMap[flavorId];
  return (
    <div
      className="bg-light d-flex align-items-center justify-content-center rounded-circle wcl-text-size-lg2 p-2 border"
      style={
        {
          width: 38,
          height: 38,
          '--bs-border-color': '#00000010',
        } as React.CSSProperties
      }
    >
      <img
        className={cs('img-fluid', 'mh-100', 'wcl-image-shadow')}
        src={flavor.image}
        alt={flavor.name}
      />
    </div>
  );
}
