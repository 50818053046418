// list of available wines to be tasted
export const wines = [
  {
    id: 'dragonette-happy-canyon-sauvignon-blanc-2021',
    wineryName: 'Dragonette',
    name: 'Happy Canyon Sauvignon Blanc 2021',
    type: 'white',
    bottleImage: require('./images/wines/bottles/dragonette-happy-canyon-sauvignon-blanc-2021.png'),
    vineyardImage: require('./images/wines/vineyard/happy-canyon.jpg'),
  },
  {
    id: 'dragonette-grimms-bluff-sauvignon-blanc-2020',
    wineryName: 'Dragonette',
    name: "Grimm's Bluff Sauvignon Blanc 2020",
    type: 'white',
    bottleImage: require('./images/wines/bottles/dragonette-grimms-bluff.png'),
    vineyardImage: require('./images/wines/vineyard/grimms-bluff.png'),
  },
  {
    id: 'dragonette-grassini-sauvignon-blanc-2020',
    wineryName: 'Dragonette',
    name: 'Grassini Family Vineyard Sauvignon Blanc 2020',
    type: 'white',
    bottleImage: require('./images/wines/bottles/dragonette-grassini.png'),
    vineyardImage: require('./images/wines/vineyard/grassini-vineyard.png'),
  },
  {
    id: 'dragonette-sta-rita-chardonnay-2021',
    wineryName: 'Dragonette',
    name: 'Sta Rita Hills Chardonnay 2021',
    type: 'white',
    bottleImage: require('./images/wines/bottles/dragonette-chardonnay.png'),
    vineyardImage: require('./images/wines/vineyard/srh-chardonnay-ritas-crown.png'),
  },
  {
    id: 'dragonette-black-label-2020',
    wineryName: 'Dragonette',
    name: 'Black Label Pinot Noir 2020',
    type: 'red',
    bottleImage: require('./images/wines/bottles/dragonette-black-label.png'),
    vineyardImage: require('./images/wines/vineyard/bentrock.png'),
  },
  {
    id: 'dragonette-radian-pinot-2020',
    wineryName: 'Dragonette',
    name: 'Radian Vineyard Pinot Noir 2020',
    type: 'red',
    bottleImage: require('./images/wines/bottles/dragonette-radian.png'),
    vineyardImage: require('./images/wines/vineyard/radian.png'),
  },
  {
    id: 'schramsberg-b-de-b',
    wineryName: 'Schramsberg',
    name: 'Blanc de Blancs 2017',
    type: 'sparkling-white',
    bottleImage: require('./images/wines/bottles/schramsberg-b-de-b.png'),
    vineyardImage: require('./images/wines/vineyard/schramsberg-frog.png'),
  },
  {
    id: 'altaneve-prosecco-superiore',
    wineryName: 'Altaneve',
    name: 'Prosecco Superiore',
    type: 'sparkling-white',
    bottleImage: require('./images/wines/bottles/altaneve-prosecco-superiore.png'),
    vineyardImage: require('./images/wines/vineyard/prosecco-generic.png'),
  },
  {
    id: 'campo-del-drago-brunello-di-montalcino',
    wineryName: 'Castiglion del Bosco',
    name: 'Campo del Drago Brunello di Montalcino 2016',
    type: 'red',
    bottleImage: require('./images/wines/bottles/campo-del-drago-brunello-di-montalcino.png'),
    vineyardImage: require('./images/wines/vineyard/castiglion-del-bosco.png'),
  },
  {
    id: 'livio-felluga-pinot-grigio',
    wineryName: 'Livio Felluga',
    name: 'Pinot Grigio 2021',
    type: 'white',
    bottleImage: require('./images/wines/bottles/livio-felluga-pinot-grigio.png'),
    vineyardImage: require('./images/wines/vineyard/livio-felluga.png'),
  },
  {
    id: 'oddero-barola-rionda-riserva',
    wineryName: 'Oddero',
    name: 'Barolo Vignarionda Riserva 2014',
    type: 'red',
    bottleImage: require('./images/wines/bottles/oddero-barola-rionda-riserva.png'),
    vineyardImage: require('./images/wines/vineyard/oddero-rionda.png'),
  },
  {
    id: 'produttori-ovello-riserva',
    wineryName: 'Produttori del Barbaresco',
    name: 'Barbaresco Riserva Ovello 2017',
    type: 'red',
    bottleImage: require('./images/wines/bottles/produttori-ovello-riserva.png'),
    vineyardImage: require('./images/wines/vineyard/produttori-ovello.png'),
  },
  {
    id: 'hanzell-chardonnay',
    wineryName: 'Hanzell',
    name: 'Chardonnay 2017',
    type: 'white',
    bottleImage: require('./images/wines/bottles/hanzell-chardonnay.png'),
    vineyardImage: require('./images/wines/vineyard/hanzell-zellerbach.png'),
  },
  {
    id: 'selbach-oster-zeltinger-schlossberg-riesling-kabinett',
    wineryName: 'Selbach Oster',
    name: 'Zeltinger Schlossberg Riesling Kabinett 2019',
    type: 'white',
    bottleImage: require('./images/wines/bottles/selbach-oster-zeltinger-schlossberg-kabinett.png'),
    vineyardImage: require('./images/wines/vineyard/zeltinger-schlossberg.png'),
  },
  {
    id: 'leeuwin-as-chardonnay-2019',
    wineryName: 'Leeuwin Estate',
    name: 'Art Series Chardonnay 2019',
    type: 'white',
    bottleImage: require('./images/wines/bottles/leeuwin-as-chardonnay-2019.png'),
    vineyardImage: require('./images/wines/vineyard/leeuwin-estate-vineyards.png'),
  },
  {
    id: 'markham-chardonnay',
    wineryName: 'Markham Vineyards',
    name: 'Chardonnay 2021',
    type: 'white',
    bottleImage: require('./images/wines/bottles/markham-chardonnay.png'),
    vineyardImage: require('./images/wines/vineyard/markham-vineyard.png'),
  },
  {
    id: 'hendry-hrw-cabernet-sauvignon',
    wineryName: 'Hendry Vineyards',
    name: '"HRW" Cabernet Sauvignon 2020',
    type: 'red',
    bottleImage: require('./images/wines/bottles/hendry-hrw-cabernet-sauvignon.png'),
    vineyardImage: require('./images/wines/vineyard/hendry-vineyard.png'),
  },
  {
    id: 'stiftskellerei-neustift-kerner-2020',
    wineryName: 'Abbazia di Novacella - Kloster Neustift',
    name: 'Kerner 2020',
    type: 'white',
    bottleImage: require('./images/wines/bottles/neustift-kerner.png'),
    vineyardImage: require('./images/wines/vineyard/stiftskellerei-neustift.png'),
  },
  {
    id: 'sa-prum-wehlener-sonnenuhr-riesling-kabinett-2017',
    wineryName: 'S.A. Prüm',
    name: 'Wehlener Sonnenuhr Riesling Kabinett 2017',
    type: 'white',
    bottleImage: require('./images/wines/bottles/sa-prum-wehlener-sonnenuhr-riesling-kabinett.png'),
    vineyardImage: require('./images/wines/vineyard/sa-prum.png'),
  },
  {
    id: 'planeta-eruzione-1614-carricante',
    wineryName: 'Planeta',
    name: 'Eruzione 1614 Carricante Etna Bianco 2018',
    type: 'white',
    bottleImage: require('./images/wines/bottles/planeta-eruzione-1614-carricante.png'),
    vineyardImage: require('./images/wines/vineyard/planeta-etna.png'),
  },
  {
    id: 'louis-latour-poilly-fuisse-2020',
    wineryName: 'Louis Latour',
    name: 'Poilly Fuisse 2020',
    type: 'white',
    bottleImage: require('./images/wines/bottles/louis-latour-pf.png'),
    vineyardImage: require('./images/wines/vineyard/random-7.png'),
  },
  {
    id: 'dragonette-sta-rita-hills-pinot-noir-2020',
    wineryName: 'Dragonette',
    name: 'Sta Rita Hills Pinot Noir 2020',
    type: 'red',
    bottleImage: require('./images/wines/bottles/dragonette-sta-rita-hills-pinot-noir-2020.png'),
    vineyardImage: require('./images/wines/vineyard/sta-rita-hills-pinot.png'),
  },
  {
    id: 'dragonette-seven-syrah-2020',
    wineryName: 'Dragonette',
    name: 'Seven Syrah 2020',
    type: 'red',
    // bottleImage: require('./images/wines/bottles/dragonette-happy-canyon-sauvignon-blanc-2021.png'),
    // vineyardImage: require('./images/wines/vineyard/seven-syrah.png'),
    bottleImage: require('./images/wines/bottles/dragonette-seven-syrah-2020.png'),
    vineyardImage: require('./images/wines/vineyard/ballard-canyon.jpg'),
  },
  {
    id: 'dragonette-happy-canyon-rose-2022',
    wineryName: 'Dragonette',
    name: 'Happy Canyon Rosé 2022',
    type: 'rose',
    vineyardImage: require('./images/wines/vineyard/happy-canyon-vogelzang.png'),
    bottleImage: require('./images/wines/bottles/dragonette-happy-canyon-rose.png'),
  },
  {
    id: 'dragonette-black-label-pinot-noir-2020',
    wineryName: 'Dragonette',
    name: 'Black Label Pinot Noir 2020',
    type: 'red',
    vineyardImage: require('./images/wines/vineyard/radian-moody.png'),
    bottleImage: require('./images/wines/bottles/dragonette-black-label-pinot-noir-2020.png'),
  },
  {
    id: 'sparkling-rose-test',
    wineryName: '[FAKE] Rosé Sparklétte',
    name: 'Fake Rose Sparkling',
    type: 'sparkling-rose',
    vineyardImage: require('./images/wines/vineyard/happy-canyon.jpg'),
    bottleImage: require('./images/wines/bottles/dragonette-seven-syrah-2020.png'),
  },
  {
    id: 'sparkling-white-test',
    wineryName: '[FAKE] Blanche Sparklétte',
    name: 'Fake White Sparkling',
    type: 'sparkling-white',
    vineyardImage: require('./images/wines/vineyard/happy-canyon.jpg'),
    bottleImage: require('./images/wines/bottles/dragonette-seven-syrah-2020.png'),
  },
  {
    id: 'Massican-Annia-2021',
    wineryName: 'Massican',
    name: 'Annia 2021',
    type: 'white',
    vineyardImage: require('./images/wines/vineyard/nichellini-family-vineyard.png'),
    bottleImage: require('./images/wines/bottles/Massican-Annia-2021.png'),
  },
  {
    id: 'letterhead-chardonnay-2021',
    wineryName: 'Letterhead',
    name: 'Chardonnay Brown Ranch Vineyard Carneros 2021',
    type: 'white',
    vineyardImage: require('./images/wines/vineyard/brown-ranch-vineyard.png'),
    bottleImage: require('./images/wines/bottles/letterhead-chardonnay.png'),
  },
  {
    id: 'merry-edwards-sauvignon-blanc',
    wineryName: 'Merry Edwards',
    name: 'Sauvignon Blanc 2019',
    type: 'white',
    vineyardImage: require('./images/wines/vineyard/merry-edwards-maefield-vineyard.png'),
    bottleImage: require('./images/wines/bottles/merry-edwards-sauvignon-blanc.png'),
  },
  {
    id: 'merry-edwards-pinot-noir',
    wineryName: 'Merry Edwards',
    name: 'Pinot Noir 2020',
    type: 'red',
    vineyardImage: require('./images/wines/vineyard/merry-edwards-pinot.png'),
    bottleImage: require('./images/wines/bottles/merry-edwards-russian-river-pinot-noir.png'),
  },
  {
    id: 'brancott-estate-b-sauvignon-blanc',
    wineryName: 'Brancott',
    name: 'Estate B Sauvignon Blanc 2020',
    type: 'white',
    vineyardImage: require('./images/wines/vineyard/brancott.png'),
    bottleImage: require('./images/wines/bottles/brancott-estate-b.png'),
  },
  {
    id: 'bergstrom-cumberland-reserve-pinot-noir',
    wineryName: 'Bergstrom',
    name: 'Cumberland Reserve Pinot Noir 2020',
    type: 'red',
    vineyardImage: require('./images/wines/vineyard/bergstrom.png'),
    bottleImage: require('./images/wines/bottles/bergstrom-cumberland-reserve-pinot-noir.png'),
  },
  {
    id: 'billecart-salmon-brut-rose',
    wineryName: 'Billecart-Salmon',
    name: 'Brut Rose',
    type: 'sparkling-rose',
    vineyardImage: require('./images/wines/vineyard/billecart-salmon.png'),
    bottleImage: require('./images/wines/bottles/billecart-salmon-brut-rose.png'),
  },
  {
    id: 'coppola-diamond-prosecco',
    wineryName: 'Francis Ford-Coppola',
    name: 'Diamond Collection Prosecco',
    type: 'sparkling-white',
    vineyardImage: require('./images/wines/vineyard/prosecco-generic.png'),
    bottleImage: require('./images/wines/bottles/coppola-diamond-prosecco.png'),
  },
  {
    id: 'altesino-brunello-di-montalcino',
    wineryName: 'Altesino',
    name: 'Brunello di Montalcino 2017',
    type: 'red',
    vineyardImage: require('./images/wines/vineyard/altesino-macina.png'),
    bottleImage: require('./images/wines/bottles/altesino-brunello-di-montalcino.png'),
  },
  {
    id: 'monsanto-chianti-classico-riserva',
    wineryName: 'Castello di Monsanto',
    name: 'Chianti Classico Riserva 2019',
    type: 'red',
    vineyardImage: require('./images/wines/vineyard/castello-monsanto.png'),
    bottleImage: require('./images/wines/bottles/monsanto-chianti-classico-riserva.png'),
  },
  {
    id: 'podere-castorani-montepulciano-dabruzzo',
    wineryName: 'Podere Castorani',
    name: "Montepulciano d'Abruzzo Cadetto 2017",
    type: 'red',
    vineyardImage: require('./images/wines/vineyard/podere-castorani.png'),
    bottleImage: require('./images/wines/bottles/podere-castorani-montepulciano-dabruzzo.png'),
  },
  {
    id: 'basic-white-wine',
    wineryName: 'TBD',
    name: 'Gran Cru Reserve Selection NV',
    type: 'white',
    vineyardImage: require('./images/wines/vineyard/random-1.png'),
    bottleImage: require('./images/wines/bottles/blank-white.png'),
  },
  {
    id: 'tornatore-etna-rosso-trimarchisa',
    wineryName: 'Tornatore',
    name: 'Etna Rosso Trimarchisa 2018',
    type: 'red',
    vineyardImage: require('./images/wines/vineyard/sicily-vineyard.png'),
    bottleImage: require('./images/wines/bottles/tornatore-etna-rosso-trimarchisa.png'),
  },
] as const satisfies ReadonlyArray<Wine>;

export type WineId = (typeof wines)[number]['id'];

export const winesMap = wines.reduce((acc, wine) => {
  acc[wine.id] = wine;
  return acc;
}, {} as { [wineId in WineId]: Wine });
