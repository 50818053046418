export interface WineColor {
  id: string;
  name: string;
  image: string;
}

export const colors: { [wineType in Wine['type']]: Array<WineColor> } = {
  white: [
    {
      id: 'pale-straw',
      name: 'Pale Straw',
      image: require('./images/colors/white/pale-straw.png'),
    },
    {
      id: 'pale-yellow',
      name: 'Pale Yellow',
      image: require('./images/colors/white/pale-yellow.png'),
    },
    {
      id: 'pale-gold',
      name: 'Pale Gold',
      image: require('./images/colors/white/pale-gold.png'),
    },
    {
      id: 'medium-straw',
      name: 'Medium Straw',
      image: require('./images/colors/white/medium-straw.png'),
    },
    {
      id: 'medium-yellow',
      name: 'Medium Yellow',
      image: require('./images/colors/white/medium-yellow.png'),
    },
    {
      id: 'medium-gold',
      name: 'Medium Gold',
      image: require('./images/colors/white/medium-gold.png'),
    },
    {
      id: 'deep-straw',
      name: 'Deep Straw',
      image: require('./images/colors/white/deep-straw.png'),
    },
    {
      id: 'deep-yellow',
      name: 'Deep Yellow',
      image: require('./images/colors/white/deep-yellow.png'),
    },
    {
      id: 'deep-gold',
      name: 'Deep Gold',
      image: require('./images/colors/white/deep-gold.png'),
    },
  ],
  red: [
    {
      id: 'pale-garnet',
      name: 'Pale Garnet',
      image: require('./images/colors/red/pale-garnet.png'),
    },
    {
      id: 'pale-ruby',
      name: 'Pale Ruby',
      image: require('./images/colors/red/pale-ruby.png'),
    },
    {
      id: 'pale-purple',
      name: 'Pale Purple',
      image: require('./images/colors/red/pale-purple.png'),
    },
    {
      id: 'medium-garnet',
      name: 'Medium Garnet',
      image: require('./images/colors/red/medium-garnet.png'),
    },
    {
      id: 'medium-ruby',
      name: 'Medium Ruby',
      image: require('./images/colors/red/medium-ruby.png'),
    },
    {
      id: 'medium-purple',
      name: 'Medium Purple',
      image: require('./images/colors/red/medium-purple.png'),
    },
    {
      id: 'deep-garnet',
      name: 'Deep Garnet',
      image: require('./images/colors/red/deep-garnet.png'),
    },
    {
      id: 'deep-ruby',
      name: 'Deep Ruby',
      image: require('./images/colors/red/deep-ruby.png'),
    },
    {
      id: 'deep-purple',
      name: 'Deep Purple',
      image: require('./images/colors/red/deep-purple.png'),
    },
  ],
  rose: [
    {
      id: 'pale-salmon',
      name: 'Pale Salmon',
      image: require('./images/colors/rose/pale-salmon.png'),
    },
    {
      id: 'pale-pink',
      name: 'Pale Pink',
      image: require('./images/colors/rose/pale-pink.png'),
    },
    {
      id: 'pale-copper',
      name: 'Pale Copper',
      image: require('./images/colors/rose/pale-copper.png'),
    },
    {
      id: 'medium-salmon',
      name: 'Medium Salmon',
      image: require('./images/colors/rose/medium-salmon.png'),
    },
    {
      id: 'medium-pink',
      name: 'Medium Pink',
      image: require('./images/colors/rose/medium-pink.png'),
    },
    {
      id: 'medium-copper',
      name: 'Medium Copper',
      image: require('./images/colors/rose/medium-copper.png'),
    },
    {
      id: 'deep-salmon',
      name: 'Deep Salmon',
      image: require('./images/colors/rose/deep-salmon.png'),
    },
    {
      id: 'deep-pink',
      name: 'Deep Pink',
      image: require('./images/colors/rose/deep-pink.png'),
    },
    {
      id: 'deep-copper',
      name: 'Deep Copper',
      image: require('./images/colors/rose/deep-copper.png'),
    },
  ],
  'sparkling-rose': [
    {
      id: 'pale-salmon',
      name: 'Pale Salmon',
      image: require('./images/colors/sparkling-rose/palesalmonsparkling.png'),
    },
    {
      id: 'pale-pink',
      name: 'Pale Pink',
      image: require('./images/colors/sparkling-rose/palepinksparkling.png'),
    },
    {
      id: 'pale-peach',
      name: 'Pale Peach',
      image: require('./images/colors/sparkling-rose/palepeachsparkling.png'),
    },
    {
      id: 'medium-salmon',
      name: 'Medium Salmon',
      image: require('./images/colors/sparkling-rose/mediumsalmonsparkling.png'),
    },
    {
      id: 'medium-pink',
      name: 'Medium Pink',
      image: require('./images/colors/sparkling-rose/mediumpinksparkling.png'),
    },
    {
      id: 'medium-peach',
      name: 'Medium Peach',
      image: require('./images/colors/sparkling-rose/mediumpeachsparkling.png'),
    },

    {
      id: 'deep-salmon',
      name: 'Deep Salmon',
      image: require('./images/colors/sparkling-rose/deepsalmonsparkling.png'),
    },
    {
      id: 'deep-pink',
      name: 'Deep Pink',
      image: require('./images/colors/sparkling-rose/deeppinksparkling.png'),
    },
    {
      id: 'deep-peach',
      name: 'Deep Peach',
      image: require('./images/colors/sparkling-rose/deeppeachsparkling.png'),
    },
  ],
  'sparkling-white': [
    {
      id: 'pale-straw',
      name: 'Pale Straw',
      image: require('./images/colors/sparkling-white/pale-straw.png'),
    },
    {
      id: 'pale-lemon',
      name: 'Pale Lemon',
      image: require('./images/colors/sparkling-white/pale-lemon.png'),
    },
    {
      id: 'pale-gold',
      name: 'Pale Gold',
      image: require('./images/colors/sparkling-white/pale-gold.png'),
    },
    {
      id: 'medium-straw',
      name: 'Medium Straw',
      image: require('./images/colors/sparkling-white/medium-straw.png'),
    },
    {
      id: 'medium-lemon',
      name: 'Medium Lemon',
      image: require('./images/colors/sparkling-white/medium-lemon.png'),
    },
    {
      id: 'medium-gold',
      name: 'Medium Gold',
      image: require('./images/colors/sparkling-white/medium-gold.png'),
    },
    {
      id: 'deep-straw',
      name: 'Deep Straw',
      image: require('./images/colors/sparkling-white/deep-straw.png'),
    },
    {
      id: 'deep-lemon',
      name: 'Deep Lemon',
      image: require('./images/colors/sparkling-white/deep-lemon.png'),
    },
    {
      id: 'deep-gold',
      name: 'Deep Gold',
      image: require('./images/colors/sparkling-white/deep-gold.png'),
    },
  ],
};
