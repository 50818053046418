import cs from 'classnames';

import styles from './Header.module.scss';

interface Props {
  wine: Wine;
  sideContent?: JSX.Element;
}

export function Header({ wine, sideContent }: Props): JSX.Element {
  return (
    <div
      className={cs(
        'shadow-sm bg-light p-4 d-flex flex-column ',
        styles.container
      )}
      style={{ backgroundImage: `url(${wine.vineyardImage})` }}
    >
      <div className="d-flex my-2">
        <div className="flex-grow-1">
          <div className="d-flex align-items-center gap-3 mb-2">
            {/* <span className="navbar-toggler-icon" /> */}
            <h1 className="mb-0">{wine.wineryName}</h1>
          </div>
          <h3 className="fw-normal mb-0">{wine.name}</h3>
        </div>
        {sideContent ? (
          <div className="align-self-center ms-3">{sideContent}</div>
        ) : null}
      </div>
    </div>
  );
}
