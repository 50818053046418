import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';

import { URLS } from 'src/config';

import { RootPageShell } from 'src/components/pages-structures';
import {
  LoginPage,
  GoogleAuthCallbackPage,
  RequireAuthPage,
} from 'src/features/auth/pages';
import { EventsListPage, EventPage } from 'src/features/events/pages';
import {
  TastingNotesPage,
  TastingNotesWineScoreboardPage,
} from 'src/features/tasting-notes/pages';

export function createRouter() {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootPageShell />}>
        <Route path="login" element={<LoginPage />} />
        <Route
          path={URLS.AUTH.OAUTH_CALLBACK_PATH}
          element={<GoogleAuthCallbackPage />}
        />
        <Route element={<RequireAuthPage />}>
          <Route index={true} element={<EventsListPage />} />
          <Route path="event/:eventId">
            <Route index={true} element={<EventPage />} />
            <Route path="tasting-notes/:wineId">
              <Route index={true} element={<TastingNotesPage />} />
              <Route
                path="scores"
                element={<TastingNotesWineScoreboardPage />}
              />
            </Route>
          </Route>
        </Route>
      </Route>
    )
  );
}
