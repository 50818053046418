import { flavorsMap, WineFlavorKey } from 'src/data/flavors';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { TastingNote } from 'src/features/tasting-notes/types';
import { UserAvatar } from 'src/components/primitives';
import { FlavorCircle } from './FlavorCircle';

interface Props {
  tastingNote: TastingNote;
}

export function UserWineScore({ tastingNote }: Props) {
  const firstName = tastingNote.user.name.split(' ')[0];

  return (
    <div className="bg-white shadow-sm d-flex align-items-center mb-2 px-2 py-2 rounded-1">
      <div className="flex-shrink-0">
        <UserAvatar size="2.5rem" user={tastingNote.user} />
      </div>
      <div
        className="ms-2 me-1 flex-shrink-0 flex-grow-0 text-truncate"
        style={{ flexBasis: '20%' }}
      >
        {firstName}
      </div>
      <div className="d-flex gap-1 me-auto">
        <div
          className="bg-light d-flex align-items-center justify-content-center rounded-circle wcl-text-size-lg2"
          style={{ width: 38, height: 38 }}
        >
          {tastingNote.score}
        </div>
        {tastingNote.flavors.map((flavorId) => (
          <OverlayTrigger
            placement="top"
            overlay={(props) => (
              <Tooltip id="button-tooltip" {...props}>
                <div className="px-1 pb-1 pt-2">
                  {flavorsMap[flavorId as WineFlavorKey].name}
                </div>
              </Tooltip>
            )}
          >
            <div key={flavorId}>
              <FlavorCircle flavorId={flavorId as WineFlavorKey} />
            </div>
          </OverlayTrigger>
        ))}
      </div>
    </div>
  );
}
