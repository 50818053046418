import React from 'react';
import cs from 'classnames';
import Overlay from 'react-bootstrap/Overlay';

import plusSignImage from 'src/components/primitives/plus-sign.svg';

import { MyScoreModal } from './MyScoreModal/MyScoreModal';
import styles from './MyScoreButton.module.scss';

interface Props {
  wineType: Wine['type'];
  myScore: number | null;
  onChange: (myScore: number) => unknown;
}

export function MyScoreButton({
  wineType,
  myScore,
  onChange,
}: Props): JSX.Element {
  const [show, setShow] = React.useState(false);
  const targetRef = React.useRef<HTMLButtonElement | null>(null);

  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);

  React.useEffect(() => {
    forceUpdate();
  }, []);

  return (
    <>
      <Overlay
        target={targetRef}
        show={show}
        placement="bottom"
        rootClose
        onHide={() => setShow(false)}
      >
        {(overlayProps) => (
          <MyScoreModal
            wineType={wineType}
            onChange={onChange}
            myScore={myScore}
            onClose={() => setShow(false)}
            {...overlayProps}
          />
        )}
      </Overlay>
      <button
        ref={targetRef}
        className={cs(
          'btn btn-light d-flex flex-column align-items-center justify-content-center p-0 shadow',
          styles.button
        )}
        onClick={() => setShow(!show)}
      >
        <div className={cs(styles.score)}>
          {myScore ?? (
            <img
              className={cs('img-fluid mb-2', styles.add_image)}
              src={plusSignImage}
              alt="Add score"
            />
          )}
        </div>
        <div className="wcl-text-size-sm1">My Score</div>
      </button>
    </>
  );
}
