import { RouterProvider } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';

import { useDisableIosTextFieldZoom } from './utils';
import { createQueryClient, createRouter } from './config';

const queryClient = createQueryClient();

const router = createRouter();

export function App(): JSX.Element {
  useDisableIosTextFieldZoom();

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}
