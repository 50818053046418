interface Props {
  wine: Wine;
}

export function RotatedWineBottleImage({ wine }: Props) {
  return (
    <div
      className="d-flex justify-content-center overflow-hidden align-items-center my-2 position-relative flex-column"
      style={{ maxHeight: 60 }}
    >
      <hr className="position-absolute start-0 end-0 mx-4" />
      <img
        src={wine.bottleImage}
        alt=""
        className="img-fluid "
        style={{ transform: 'rotate(90deg)', width: 25 }}
      />
    </div>
  );
}
