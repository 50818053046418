import { maxSelectedVibes } from 'src/data/vibes';

import { ValuePickerWithModal } from 'src/components/primitives/ValuePickerWithModal';

import { WineVibePickerModal } from './WineVibePickerModal';
import {
  WineVibeRoundedButton,
  VIBE_ROUNDED_BUTTON_SIZE,
} from './components/WineVibeRoundedButton';

interface Props {
  selectedVibes: Array<string>;
  onChange: (newVibes: Array<string>) => unknown;
}

export function WineVibePicker({ selectedVibes, onChange }: Props) {
  return (
    <ValuePickerWithModal
      label="Vibe"
      containerClass="mb-1"
      labelWidth={VIBE_ROUNDED_BUTTON_SIZE}
      values={selectedVibes}
      onChange={onChange}
      numButtons={maxSelectedVibes}
      renderButton={({ ref, disabled, value, onClick }) => (
        <WineVibeRoundedButton
          ref={ref}
          vibe={value}
          disabled={disabled}
          onClick={onClick}
        />
      )}
      renderModal={({ selected, onToggle, onClose, overlayProps }) => (
        <WineVibePickerModal
          selected={selected}
          onToggle={onToggle}
          onClose={onClose}
          {...overlayProps}
        />
      )}
    />
  );
}
