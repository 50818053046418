import { type WineId } from './wines';

export interface Event {
  id: string;
  name: string;
  type: 'regular' | 'DIY';
  wines: ReadonlyArray<{
    title: string;
    wineId: WineId;
  }>;
  respectOrdering?: boolean;
}

export const events = [
  {
    id: 'my-first-event',
    name: 'My First Event',
    type: 'regular',
    respectOrdering: false,
    wines: [
      {
        title: 'Kick/Off',
        wineId: 'dragonette-happy-canyon-sauvignon-blanc-2021',
      },
      {
        title: 'National Treasure',
        wineId: 'dragonette-sta-rita-hills-pinot-noir-2020',
      },
      {
        title: 'Lord of Wine',
        wineId: 'dragonette-seven-syrah-2020',
      },
      {
        title: 'The Unbeareable Weight of Massive Tannins',
        wineId: 'dragonette-happy-canyon-rose-2022',
      },
    ],
  },
  {
    id: 'go-chard-or-go-home',
    name: 'BOG Demo',
    type: 'regular',
    respectOrdering: false,
    wines: [
      {
        title: 'A Sparkling Start',
        wineId: 'schramsberg-b-de-b',
      },
      {
        title: 'From Ops to Crops',
        wineId: 'letterhead-chardonnay-2021',
      },
    ],
  },
  {
    id: 'o-mi-nebbiolo-caro',
    name: 'O Mi Nebbiolo Caro',
    type: 'regular',
    respectOrdering: false,
    wines: [
      {
        title: 'Overture',
        wineId: 'altaneve-prosecco-superiore',
      },
      {
        title: 'Prelude',
        wineId: 'livio-felluga-pinot-grigio',
      },
      {
        title: 'Aria',
        wineId: 'produttori-ovello-riserva',
      },
      {
        title: 'Aria',
        wineId: 'oddero-barola-rionda-riserva',
      },
      {
        title: 'Encore',
        wineId: 'campo-del-drago-brunello-di-montalcino',
      },
    ],
  },
  {
    id: 'bcg-wine-society-napa-valley',
    name: 'The BCG Wine Society Presents: Napa Valley',
    type: 'regular',
    wines: [
      {
        title: 'Go Chard or go Home',
        wineId: 'markham-chardonnay',
      },
      {
        title: 'A Cabernet for Every Day',
        wineId: 'hendry-hrw-cabernet-sauvignon',
      },
    ],
  },
  {
    id: 'taste-and-learn-kenan-flagler',
    name: 'KFWS',
    type: 'regular',
    wines: [
      {
        title: 'Fresh from the West',
        wineId: 'dragonette-happy-canyon-sauvignon-blanc-2021',
      },
      {
        title: "There's Wine in them Hills",
        wineId: 'dragonette-sta-rita-hills-pinot-noir-2020',
      },
      {
        title: "It's getting Syrah-ious",
        wineId: 'dragonette-seven-syrah-2020',
      },
    ],
  },
  {
    id: 'kenan-flagler-taste-and-learn',
    name: 'Kenan-Flagler Wine Society',
    type: 'regular',
    wines: [
      {
        title: 'Fresh from the West',
        wineId: 'dragonette-happy-canyon-sauvignon-blanc-2021',
      },
      {
        title: "There's Wine in them Hills",
        wineId: 'dragonette-sta-rita-hills-pinot-noir-2020',
      },
      {
        title: "It's getting Syrah-ious",
        wineId: 'dragonette-seven-syrah-2020',
      },
    ],
  },
  {
    id: 'taste-and-learn-sb-pinotnoir',
    name: 'Crude and Refined',
    type: 'regular',
    wines: [
      {
        title: 'Southbound and East',
        wineId: 'dragonette-happy-canyon-sauvignon-blanc-2021',
      },
      {
        title: 'Blanc Space',
        wineId: 'merry-edwards-sauvignon-blanc',
      },
      {
        title: 'Flipping the Map',
        wineId: 'brancott-estate-b-sauvignon-blanc',
      },
      {
        title: 'The Wine, the Lawsuit, the Legend',
        wineId: 'dragonette-sta-rita-hills-pinot-noir-2020',
      },
      {
        title: 'Oregon in 60 Seconds',
        wineId: 'bergstrom-cumberland-reserve-pinot-noir',
      },
      {
        title: 'Proud Merry',
        wineId: 'merry-edwards-pinot-noir',
      },
      {
        title: 'Syrah-ing is Caring',
        wineId: 'dragonette-seven-syrah-2020',
      },
    ],
  },
  {
    id: 'cage-team-event',
    name: 'Cage Team Event',
    type: 'regular',
    respectOrdering: false,
    wines: [
      {
        title: 'Raising Nikki Coppola',
        wineId: 'coppola-diamond-prosecco',
      },
      {
        title: 'Kick/Off',
        wineId: 'billecart-salmon-brut-rose',
      },
      {
        title: 'National Treasure',
        wineId: 'selbach-oster-zeltinger-schlossberg-riesling-kabinett',
      },
      {
        title: 'Erupt-daptation',
        wineId: 'planeta-eruzione-1614-carricante',
      },
      {
        title: 'Monkstruck',
        wineId: 'stiftskellerei-neustift-kerner-2020',
      },
      {
        title: 'Lord of Wine',
        wineId: 'hanzell-chardonnay',
      },
    ],
  },
  {
    id: 'wine-chat',
    name: 'Liberté, Egalité, Sustainabilité',
    type: 'regular',
    wines: [
      {
        title: 'From Ops to Crops: A Winery is Born',
        wineId: 'letterhead-chardonnay-2021',
      },
      {
        title: 'A little Fuissé, Not too Chablis',
        wineId: 'louis-latour-poilly-fuisse-2020',
      },
    ],
  },
  {
    id: 'taste-and-learn',
    name: 'Taste and Learn',
    type: 'regular',
    wines: [
      {
        title: 'Wine 1',
        wineId: 'dragonette-happy-canyon-sauvignon-blanc-2021',
      },
      {
        title: 'Wine 2',
        wineId: 'dragonette-sta-rita-chardonnay-2021',
      },
      {
        title: 'Wine 3',
        wineId: 'dragonette-grimms-bluff-sauvignon-blanc-2020',
      },
      {
        title: 'Wine 4',
        wineId: 'dragonette-grassini-sauvignon-blanc-2020',
      },
      {
        title: 'Wine 5',
        wineId: 'dragonette-seven-syrah-2020',
      },
      {
        title: 'Wine 6',
        wineId: 'dragonette-sta-rita-hills-pinot-noir-2020',
      },
      {
        title: 'Wine 7',
        wineId: 'dragonette-black-label-2020',
      },
    ],
  },
] as const satisfies ReadonlyArray<Event>;
