import { ReactNode } from 'react';

export interface WineVibe {
  id: string;
  name: string;
  image: string;
  description: ReactNode;
}

export const maxSelectedVibes = 4;

// used in the Vibe modal to differentiate the "My Wine" option from the others
export const myWineId = 'my-wine';

export const vibes: Array<WineVibe> = [
  {
    id: myWineId,
    name: 'My Wine',
    image: require('./images/vibes/my-wine.png'),
    description: (
      <>
        'Your' wine is a wine that just <i>feels like you</i>. It's like making
        friends - you don't have to think about, just feel it.
      </>
    ),
  },
  {
    id: 'relax-refresh',
    name: 'Relax, Refresh',
    image: require('./images/vibes/relax-refresh.png'),
    description:
      "Also known as a 'porch pounder', this wine chills out and hits all the right notes",
  },
  {
    id: 'home-at-last',
    name: 'Home at Last',
    image: require('./images/vibes/home-at-last.png'),
    description:
      "Long day at work? Put up your feet, pull up a glass, and let your hair down. You're home now.",
  },
  {
    id: 'winner-with-dinner',
    name: 'A winner with dinner',
    image: require('./images/vibes/winner-with-dinner.png'),
    description:
      'Some wines just want food to complete the picture. This is one of those wines.',
  },
  {
    id: 'serious-wine',
    name: 'Serious wine',
    image: require('./images/vibes/serious-wine.png'),
    description:
      "You don't have to 'suit up' to drink this one, but if you did, it wouldn't feel weird.",
  },
  {
    id: 'treat-youself',
    name: "Treat To You'self!",
    image: require('./images/vibes/treat-youself.png'),
    description:
      "This one's all about you: indulge, unwind, celebrate yourself. It's about time!",
  },
];

// object exposing all vibes by id
export const vibesMap = vibes.reduce(
  (map, vibe) => ({ ...map, [vibe.id]: vibe }),
  {} as { [vibeId: string]: WineVibe }
);
