import { useEffect } from 'react';
import axios from 'axios';
import { useAuthTokenStore } from './auth/stores';

import { URLS } from 'src/config';

// https://developers.google.com/identity/protocols/oauth2/web-server

// required on each call
const redirectField = {
  redirect_uri: `${window.location.origin}/${URLS.AUTH.OAUTH_CALLBACK_PATH}`,
};

// this is the first step of the oauth flow:
// 1. get from Xano the Google URL to redirect the user to
// 2. redirect user to that URL
// 3. the user will be prompted to login to google
// 4. the user will be redirected to the redirect_uri with a code
export function useGoogleAuthentication() {
  return async function startGoogleAuthentication({ from }: { from: string }) {
    const { authUrl } = (
      await axios.get(URLS.AUTH.GOOGLE_OAUTH_INIT, {
        params: {
          from,
          ...redirectField,
        },
      })
    ).data;

    window.location.href = authUrl;
  };
}

// this is the second step of the oauth flow:
// 1. this should be used in the callback page
// 2. get the code from the URL
// 3. use the code to get an access token from Xano
// 4. xano will create a new user if the user using the google doesn't exist
export async function useAuthorizationCodeToGetAccessToken(
  callback: (args: {
    getAccessToken: (authorizationCode: string | null) => Promise<string>;
  }) => unknown
) {
  const token = useAuthTokenStore((s) => s.authToken);

  useEffect(() => {
    async function getAccessToken(authorizationCode: string | null) {
      if (authorizationCode != null) {
        const params = new URLSearchParams({
          code: authorizationCode,
          ...redirectField,
        }).toString();

        const url = `${URLS.AUTH.GOOGLE_OAUTH_CONTINUE}?${params}`;
        return (await axios.get(url)).data.token;
      }
    }

    // if the access token is already set, no need to get it again
    if (token == null) {
      callback({ getAccessToken });
    }
  }, [token, callback]);
}
