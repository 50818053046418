// base paths
const XANO_ACCOUNT_BASE = 'https://xjat-mqol-lz9k.n7.xano.io';
const XANO_GOOGLE_OAUTH_API_BASE = `${XANO_ACCOUNT_BASE}/api:QcX3DdcO`;
const XANO_USER_API_BASE = `${XANO_ACCOUNT_BASE}/api:XDJTFqh_`;
const XANO_TASTING_NOTE_API_BASE = `${XANO_ACCOUNT_BASE}/api:ZLhQLeQ_`;

// app URLS organized by domain
export const URLS = {
  AUTH: {
    OAUTH_CALLBACK_PATH: 'google-auth-callback/',
    GOOGLE_OAUTH_INIT: `${XANO_GOOGLE_OAUTH_API_BASE}/oauth/google/init`,
    GOOGLE_OAUTH_CONTINUE: `${XANO_GOOGLE_OAUTH_API_BASE}/oauth/google/continue`,
    AUTH_CURRENT_USER: `${XANO_USER_API_BASE}/auth/me`,
  },
  EVENTS: {
    TASTING_NOTES: `${XANO_TASTING_NOTE_API_BASE}/tasting-notes`,
  },
};
