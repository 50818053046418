import React from 'react';
import { Outlet } from 'react-router-dom';

import { LoadingPage } from './LoadingPage';

export function RootPageShell(): JSX.Element {
  return (
    <React.Suspense fallback={<LoadingPage />}>
      <Outlet />
    </React.Suspense>
  );
}
