import { useState } from 'react';

import { HowItWorksModal } from './HowItWorksModal';
import { WordsToTasteByModal } from './WordsToTasteByModal';

export function FooterLinks() {
  const [showWordsToTasteByModal, setShowWordsToTasteByModal] = useState(false);

  const [showHowItWorksModal, setShowHowItWorksModal] = useState(false);

  return (
    <div className="d-flex mx-1 my-2 justify-content-center">
      {/* <div
        style={{ flexBasis: '50%' }}
        className="d-flex justify-content-end align-items-center"
      >
        <button
          className="py-0 text-decoration-none btn btn-link text-end w-100"
          onClick={() => setShowHowItWorksModal(true)}
        >
          How it
          <br />
          works
        </button>
      </div> */}
      <div
        // style={{ flexBasis: '50%' }}
        className="border-dark d-flex justify-content-start align-items-center"
      >
        <button
          className="py-0 text-decoration-none btn btn-link text-start w-100"
          onClick={() => setShowWordsToTasteByModal(true)}
        >
          Words to taste by
        </button>
      </div>
      <WordsToTasteByModal
        show={showWordsToTasteByModal}
        onHide={() => setShowWordsToTasteByModal(false)}
      />
      <HowItWorksModal
        show={showHowItWorksModal}
        onHide={() => setShowHowItWorksModal(false)}
      />
    </div>
  );
}
