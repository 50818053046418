import Modal from 'react-bootstrap/Modal';
import cs from 'classnames';

import styles from './WordsToTasteByModal.module.scss';

interface Props {
  show: boolean;
  onHide: () => unknown;
}

export function WordsToTasteByModal({ show, onHide }: Props) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      contentClassName="mb-5"
      dialogClassName="pb-5"
    >
      <Modal.Body className="text-center">
        <div className="position-relative px-4 pt-4">
          <button
            type="button"
            className="btn-close position-absolute top-0 end-0"
            aria-label="Close"
            onClick={onHide}
          />
          <h2 className="fw-semibold mb-4">Words to Taste By</h2>
          <h2 className="fw-light mb-5">
            Tasting notes aren't scientific descriptions - they're personal
            incantations that remind you how a wine made you feel.
          </h2>
          <div className="d-flex justify-content-center">
            <ol
              className={cs(
                'text-start',
                'fw-light',
                'wcl-text-size-lg2',
                'ps-0',
                styles.list
              )}
            >
              <li className="mb-4">There are no 'wrong' or 'right' flavors.</li>
              <li className="mb-4">
                If you taste it, it's there. If you like it, you're right.
              </li>
              <li className="mb-4">
                Tasting wine beats talking about wine any day.
              </li>
            </ol>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
