import { PageFrame } from 'src/components/pages-structures';

import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';
import { useWineParam } from 'src/features/tasting-notes/use-wine-param-hook';

interface Props {
  pageTitle: string;
  headerSideContent?: JSX.Element;
  footerBackButtonHref?: string;
  footerSubmitButtonContent?: string | JSX.Element;
  footerContent?: JSX.Element;
  onSubmit?: () => Promise<unknown>;
  children: JSX.Element;
}

export function TastingNotePageShell({
  pageTitle,
  headerSideContent,
  footerContent,
  footerBackButtonHref,
  footerSubmitButtonContent,
  onSubmit,
  children,
}: Props) {
  const { wine } = useWineParam();

  return (
    <PageFrame
      header={<Header wine={wine} sideContent={headerSideContent} />}
      footer={
        <Footer
          pageTitle={pageTitle}
          backButtonHref={footerBackButtonHref}
          submitButtonContent={footerSubmitButtonContent}
          onSubmit={onSubmit}
        >
          {footerContent}
        </Footer>
      }
    >
      {children}
    </PageFrame>
  );
}
