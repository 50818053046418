interface Props {
  bottle: JSX.Element;
  vibe: JSX.Element;
  color: JSX.Element;
  flavors: JSX.Element;
  notesInput: JSX.Element;
}

export function Content({
  bottle,
  vibe,
  color,
  flavors,
  notesInput,
}: Props): JSX.Element {
  return (
    <>
      <div className="py-3 d-flex gap-2 flex-grow-1 gap-4">
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ flexBasis: '30%' }}
        >
          {bottle}
        </div>
        <div
          className="d-flex flex-column gap-2 justify-content-center"
          style={{ flexBasis: '70%' }}
        >
          <div>{vibe}</div>
          <div className="d-flex gap-4">
            <div style={{ flexBasis: '33%' }}>{color}</div>
            <div>{flavors}</div>
          </div>
          <div>{notesInput}</div>
        </div>
      </div>
      <div className="" style={{ flexBasis: '10%' }} />
    </>
  );
}
