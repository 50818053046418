import { useEffect, useState } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';

import { ExternalPageShell } from 'src/components/pages-structures';
import { useUser } from 'src/utils/auth/stores';
import { useUserAuthState } from 'src/utils/auth/use-user';
import { ErrorBoundaryPage } from 'src/components/general-pages';

import { WelcomePanel } from './components/WelcomePanel';
import { useEventParam } from 'src/features/events/use-event-param-hook';

const SKIP_TIMER = process.env.REACT_APP_BYPASS_WELCOME_TIMER === '1';

export function RequireAuthPage() {
  const { event } = useEventParam(false);
  const [readyToGo, setReadyToGo] = useState(false);
  const userAuthState = useUserAuthState();
  const user = useUser();
  const location = useLocation();

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout> | null = null;

    // if there's no authToken, status will be "idle", we continue assuming user isn't authenticated
    // if there's authToken, initial status will be "loading", then:
    // - if user is authenticated, status will be "success"
    // - if user is not authenticated, status will be "error"
    if (!userAuthState.isLoading) {
      setReadyToGo(false);
      timerId = setTimeout(() => setReadyToGo(true), SKIP_TIMER ? 0 : 2000);
    }

    return () => {
      timerId && clearTimeout(timerId);
    };
  }, [userAuthState.isLoading]);

  return !readyToGo ? (
    <ExternalPageShell showTopConnoisseur={false}>
      <WelcomePanel />
    </ExternalPageShell>
  ) : user == null ? (
    <Navigate
      to="/login"
      state={{ from: location.pathname, event: event?.name }}
      replace
    />
  ) : (
    <ErrorBoundaryPage>
      <Outlet />
    </ErrorBoundaryPage>
  );
}
