import React from 'react';

interface Props {
  notes: string;
  onChange: (notes: string) => unknown;
}

export function WineNotesInputField({ notes, onChange }: Props) {
  const fieldId = React.useId();

  return (
    <div className="me-4">
      <textarea
        id={fieldId}
        className="form-control fst-italic text-primary"
        placeholder="Add a description... (optional)"
        value={notes}
        onChange={(e) => onChange(e.target.value)}
        style={{ height: '7rem' }}
      />
    </div>
  );
}
