import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { type WriteTastingNote } from 'src/features/tasting-notes/types';
import { useEventTastingNoteCreateOrUpdate } from 'src/features/tasting-notes/tasting-notes-queries';

export function useTastingData(tastingNoteSource: WriteTastingNote) {
  const navigate = useNavigate();

  const [tastingNote, setTastingNote] =
    useState<WriteTastingNote>(tastingNoteSource);

  const { mutateAsync: createOrUpdateTastingNote } =
    useEventTastingNoteCreateOrUpdate();

  async function submitData(): Promise<unknown> {
    return createOrUpdateTastingNote(tastingNote).then((r) => {
      navigate(
        `/event/${tastingNote.event_id}/tasting-notes/${tastingNote.wine_id}/scores`
      );
      return r;
    });
  }

  function updateFieldFn<F extends keyof WriteTastingNote>(field: F) {
    return (value: WriteTastingNote[F]) =>
      setTastingNote((tn) => ({ ...tn, [field]: value }));
  }

  return {
    tastingNote,
    setFlavors: updateFieldFn('flavors'),
    setVibes: updateFieldFn('vibes'),
    setDescription: updateFieldFn('description'),
    setScore: updateFieldFn('score'),
    setColor: updateFieldFn('color'),
    submitData,
  };
}
