import { Link } from 'react-router-dom';

import { events } from 'src/data/events';
import { useAuthTokenStore } from 'src/utils/auth/stores';

export function EventsListPage(): JSX.Element {
  const setAuthToken = useAuthTokenStore((state) => state.setAuthToken);

  return (
    <div className="p-4">
      Events:
      <ul>
        {events.map((event) => (
          <li key={event.id}>
            <Link
              to={`/event/${event.id}`}
              className="wcl-text-size-lg1 py-1 d-block"
            >
              {event.name}
            </Link>
          </li>
        ))}
      </ul>
      <button
        className="btn btn-sm btn-dark"
        onClick={() => setAuthToken(null)}
      >
        Logout
      </button>
    </div>
  );
}
