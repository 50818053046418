import { WineFlavorKey } from 'src/data/flavors';

import { Content } from './components/Content';
import { BottleImage } from './components/Content/BottleImage';
import { WineColorPicker } from './components/Content/WineColorPicker';
import { WineFlavorPicker } from './components/Content/WineFlavorPicker';
import { WineVibePicker } from './components/Content/WineVibePicker';
import { WineNotesInputField } from './components/Content/WineNotesInputField';

import { useTastingData } from './use-tasting-data';
import type { WriteTastingNote } from 'src/features/tasting-notes/types';
import { useEventParam } from 'src/features/events/use-event-param-hook';
import { useMyEventTastingNotes } from 'src/features/tasting-notes/tasting-notes-queries';
import { useWineParam } from '../../use-wine-param-hook';
import { TastingNotePageShell } from 'src/features/tasting-notes/components';

import { MyScoreButton, FooterLinks } from './components';

export function TastingNotesPage() {
  const { wine, startingTastingNote } = useEventTastingNote();

  const {
    tastingNote,
    setFlavors,
    setVibes,
    setDescription,
    setScore,
    setColor,
    submitData,
  } = useTastingData(startingTastingNote);

  return (
    <TastingNotePageShell
      headerSideContent={
        <MyScoreButton
          onChange={setScore}
          myScore={tastingNote.score}
          wineType={wine.type}
        />
      }
      pageTitle="Your tasting note"
      footerBackButtonHref={`/event/${tastingNote.event_id}`}
      footerSubmitButtonContent="Confirm"
      footerContent={<FooterLinks />}
      onSubmit={submitData}
    >
      <Content
        bottle={<BottleImage bottleImage={wine.bottleImage} />}
        vibe={
          <WineVibePicker
            selectedVibes={tastingNote.vibes}
            onChange={setVibes}
          />
        }
        color={
          <WineColorPicker
            selectedColor={tastingNote.color}
            onChange={setColor}
            wineType={wine.type}
          />
        }
        flavors={
          <WineFlavorPicker
            wineType={wine.type}
            selectedFlavors={tastingNote.flavors as WineFlavorKey[]}
            onChange={setFlavors}
          />
        }
        notesInput={
          <WineNotesInputField
            notes={tastingNote.description}
            onChange={setDescription}
          />
        }
      />
    </TastingNotePageShell>
  );
}

function useEventTastingNote() {
  const { event, eventId } = useEventParam(true);
  const { wine, wineId } = useWineParam();
  const myTastingNote = useMyEventTastingNotes(event!.id).data!.find(
    (tn) => tn.wine_id === wineId && tn.event_id === eventId
  );

  return {
    wine,
    startingTastingNote:
      myTastingNote ?? createBlankTastingNote(wineId!, eventId!),
  };
}

function createBlankTastingNote(
  wine_id: string,
  event_id: string
): WriteTastingNote {
  return {
    wine_id,
    event_id,
    color: null,
    description: '',
    flavors: [],
    vibes: [],
    score: null,
  };
}
