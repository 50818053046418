import React from 'react';
import { OverlayInjectedProps } from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';

import { vibes, myWineId } from 'src/data/vibes';

import { WineVibeRoundedButton } from './components/WineVibeRoundedButton';

interface Props extends OverlayInjectedProps {
  selected: string[];
  onToggle: (selected: string) => unknown;
  onClose: () => unknown;
}

export const WineVibePickerModal = React.forwardRef<HTMLDivElement, Props>(
  ({ selected, onClose, onToggle, ...overlayProps }, ref) => {
    return (
      <Popover id="popover-wine-vibe" {...overlayProps} ref={ref}>
        <Popover.Body className="text-center">
          <div className="position-relative">
            <button
              onClick={onClose}
              type="button"
              className="btn-close position-absolute top-0 end-0"
              aria-label="Close"
            />
            <div className="d-flex">
              <div style={{ flexBasis: '40%' }}>
                <div className="mx- px-1 mb-4">
                  Does it feel like 'your' wine?
                </div>
                <WineVibeRoundedButton
                  inSelectedState={selected.includes(myWineId)}
                  vibe={myWineId}
                  onClick={() => onToggle(myWineId)}
                />
              </div>
              <div style={{ flexBasis: '60%' }}>
                <div className="mx-5 px-1  mb-4">
                  What's the right moment to drink it?
                </div>
                <div className="d-flex gap-1 justify-content-center fw-light">
                  <div className="d-flex flex-wrap justify-content-start gap-3">
                    {vibes.map((vibe) =>
                      vibe.id !== myWineId ? (
                        <div key={vibe.id} style={{ flexBasis: '15%' }}>
                          <WineVibeRoundedButton
                            inSelectedState={selected.includes(vibe.id)}
                            vibe={vibe.id}
                            onClick={() => onToggle(vibe.id)}
                          />
                        </div>
                      ) : null
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  }
);
