import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { useAuthorizationCodeToGetAccessToken } from 'src/utils/google-oauth';
import { useAuthTokenStore } from 'src/utils/auth/stores';
import { ExternalPageShell } from 'src/components/pages-structures';

export function GoogleAuthCallbackPage() {
  const [searchParams] = useSearchParams();
  const { setAuthToken, authToken } = useAuthTokenStore();

  const fromURL = searchParams.get('state') ?? '/';
  const authorizationCode = searchParams.get('code');

  useAuthorizationCodeToGetAccessToken(
    React.useCallback(
      ({ getAccessToken }) => {
        getAccessToken(authorizationCode).then(setAuthToken);
      },
      [authorizationCode, setAuthToken]
    )
  );

  return authToken != null ? (
    <Navigate to={fromURL} />
  ) : (
    <ExternalPageShell>
      <ExternalPageShell.LoadingIndicator>
        Signing in...
      </ExternalPageShell.LoadingIndicator>
    </ExternalPageShell>
  );
}
