import {
  useMutation,
  MutateOptions,
  UseQueryOptions,
  useQuery,
} from 'react-query';
import axios, { Axios } from 'axios';

import { URLS } from 'src/config';
import { useAuthTokenStore } from './auth/stores';
import React from 'react';

export function headersWithAuthToken() {
  const { authToken } = useAuthTokenStore.getState();
  return {
    Authorization: `Bearer ${authToken}`,
  };
}

export function useAuthenticatedAxios() {
  const authToken = useAuthTokenStore((s) => s.authToken);
  return React.useMemo(
    () =>
      axios.create({
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }),
    [authToken]
  );
}

export function useDataQuery<TData>(
  ...args: Parameters<typeof queryBuilder<TData>>
) {
  return useQuery<TData>(queryBuilder<TData>(...args));
}

export function useDataMutation<
  TVariables = void,
  TData = unknown,
  TError = unknown
>(
  mutateFn: (
    axios: Axios,
    urls: typeof URLS
  ) => (payload: TVariables) => Promise<TData>,
  opts?: MutateOptions<TData, TError, TVariables>
) {
  const authenticatedAxios = useAuthenticatedAxios();
  return useMutation<TData, TError, TVariables>(
    mutateFn(authenticatedAxios, URLS),
    opts
  );
}

export function queryBuilder<TData>(
  [urlFn, params]: [
    urlFn: (url: typeof URLS) => string,
    params?: Record<string, any>
  ],
  options:
    | UseQueryOptions<unknown, unknown, TData, [url: string, params?: object]>
    | undefined = {}
): {
  queryKey: [url: string, params?: object];
  queryFn: () => Promise<TData>;
} {
  const url = urlFn(URLS);

  return {
    ...options,
    queryKey: [url, params],
    queryFn: () =>
      axios
        .get<TData>(url, {
          params,
          headers: headersWithAuthToken(),
        })
        .then((r) => r.data),
  };
}
