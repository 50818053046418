import cs from 'classnames';
import { motion } from 'framer-motion';

import { ConnoisseurLogo } from 'src/components/primitives';
import { useUser } from 'src/utils/auth/stores';
import { GlassImage } from 'src/components/primitives/GlassImage';

import styles from './WelcomePanel.module.scss';

export function WelcomePanel() {
  const user = useUser();

  const firstName = user?.name.split(' ')[0];

  return (
    <div className="position-relative mt-4">
      <div className="d-flex justify-content-center flex-column align-items-center">
        <div className={cs('position-absolute', styles.glassContainer)}>
          <GlassImage />
        </div>
        <div className={styles.logoContainer}>
          <ConnoisseurLogo />
        </div>
        <motion.h2
          className={cs('position-absolute', styles.messageContainer)}
          initial={{ color: 'white', translateY: '70%', translateX: '-50%' }}
          animate={{ translateY: '60%', color: '', translateX: '-50%' }}
          transition={{ delay: 0, duration: 0.75 }}
        >
          {firstName != null ? (
            <>
              Welcome,
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                {`${firstName}!`}
              </motion.div>
            </>
          ) : (
            'Welcome!'
          )}
        </motion.h2>
      </div>
    </div>
  );
}
