import { useEffect, useMemo, useRef, useState } from 'react';
import { useEventParam } from 'src/features/events/use-event-param-hook';
import { useWineParam } from 'src/features/tasting-notes/use-wine-param-hook';
import { TastingNotePageShell } from 'src/features/tasting-notes/components';
import { useEventTastingNotes } from 'src/features/tasting-notes/tasting-notes-queries';

import { UserWineScore } from './components';
import { RotatedWineBottleImage } from './components';
import { SwingingGlassImage, AnimatedList } from 'src/components/primitives';

export function TastingNotesWineScoreboardPage(): JSX.Element {
  const { eventId } = useEventParam(true);
  const { wineId, wine } = useWineParam();
  const [timedOut, setTimedOut] = useState(false);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { data: tastingNotes, error } = useEventTastingNotes(
    eventId,
    { wine_id: wineId },
    { refetchInterval: 5000, suspense: false }
  );

  useEffect(() => {
    if (error && tastingNotes == null) {
      throw error;
    }
  }, [error, tastingNotes]);

  useEffect(() => {
    timerRef.current = setTimeout(() => setTimedOut(true), 60000);
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [tastingNotes?.length]);

  const orderedNotes = useMemo(() => {
    if (tastingNotes == null) {
      return null;
    }
    return tastingNotes.sort((a, b) => (b.score ?? 0) - (a.score ?? 0));
  }, [tastingNotes]);

  return (
    <TastingNotePageShell
      pageTitle="What the team tasted"
      footerBackButtonHref={`/event/${eventId}`}
    >
      <div className="mt-4">
        <h3 className="mb-3">Your Tasting Notes</h3>
        {orderedNotes != null ? (
          <AnimatedList values={orderedNotes} keyGetter={(tn) => tn.id!}>
            {(tn) => <UserWineScore key={tn.id} tastingNote={tn} />}
          </AnimatedList>
        ) : null}
        {!timedOut && (orderedNotes == null || orderedNotes?.length === 1) ? (
          <div className="d-flex align-items-center justify-content-center mt-4 mb-3">
            <SwingingGlassImage width={20} />{' '}
            <div className="ms-2 mt-1">Collecting notes...</div>
          </div>
        ) : (
          <RotatedWineBottleImage wine={wine} />
        )}
      </div>
    </TastingNotePageShell>
  );
}
