import cs from 'classnames';
import React from 'react';

import styles from './UserAvatar.module.scss';

interface Props {
  user: { name: string; profile_image: string | null };
  size?: number | string;
}

export function UserAvatar({ user, size = '4rem' }: Props): JSX.Element {
  const { name, profile_image } = user;
  const initials = name
    .split(' ')
    .map((word) => word[0])
    .join('')
    .toUpperCase();

  const [isImageLoaded, setIsImageLoaded] = React.useState(false);

  return (
    <div
      className={cs(
        'd-flex align-items-center justify-content-center rounded-circle shadow-sm',
        styles.container
      )}
      style={{ '--size': size } as React.CSSProperties}
    >
      {!isImageLoaded ? initials : null}
      {profile_image ? (
        <img
          onLoad={() => setIsImageLoaded(true)}
          src={profile_image}
          alt={initials}
          className={cs('img-fluid', { 'd-none': !isImageLoaded })}
          referrerPolicy="no-referrer"
        />
      ) : (
        initials
      )}
    </div>
  );
}
