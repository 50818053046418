import { maxSelectedFlavors, WineFlavorKey } from 'src/data/flavors';

import { WineFlavorPickerModal } from './WineFlavorPickerModal';
import {
  FlavorRoundedButton,
  FLAVOR_ROUNDED_BUTTON_SIZE,
} from './components/FlavorRoundedButton';
import { ValuePickerWithModal } from 'src/components/primitives/ValuePickerWithModal';

interface Props {
  wineType: Wine['type'];
  selectedFlavors: Array<WineFlavorKey>;
  onChange: (value: Array<WineFlavorKey>) => unknown;
}

export function WineFlavorPicker({
  wineType,
  selectedFlavors,
  onChange,
}: Props) {
  return (
    <>
      <ValuePickerWithModal
        label="Flavors"
        labelWidth={FLAVOR_ROUNDED_BUTTON_SIZE}
        containerClass="mb-1"
        gap={2}
        values={selectedFlavors}
        onChange={onChange}
        numButtons={maxSelectedFlavors}
        renderButton={({ ref, disabled, value, onClick }) => (
          <FlavorRoundedButton
            ref={ref}
            flavor={value}
            disabled={disabled}
            onClick={onClick}
          />
        )}
        renderModal={({ selected, onToggle, onClose, overlayProps, index }) => (
          <WineFlavorPickerModal
            index={index}
            selected={selected}
            onToggle={onToggle}
            onClose={onClose}
            wineType={wineType}
            {...overlayProps}
          />
        )}
      />
    </>
  );
}
