import cs from 'classnames';

import styles from './RoundedButton.module.scss';
import plusSignImage from './plus-sign.svg';
import React from 'react';

interface Props {
  image?: string;
  size: number;
  disabled?: boolean;
  label?: string;
  onClick: () => unknown;
  isSelected?: boolean;
  textClass?: string;
}

export const RoundedButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      image,
      size,
      disabled,
      label,
      onClick,
      isSelected,
      textClass = 'wcl-text-size-sm1',
    }: Props,
    ref
  ): JSX.Element => {
    return (
      <div
        style={{ flexBasis: 0 }}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <button
          ref={ref}
          onClick={onClick}
          style={{ width: size, height: size, padding: size / (image ? 8 : 4) }}
          className={cs(
            styles.button,
            'btn btn-light d-flex align-items-center justify-content-center',
            { [styles.selected]: isSelected }
          )}
          disabled={disabled}
        >
          <img
            className={cs('img-fluid', 'mh-100', { 'wcl-image-shadow': image })}
            src={image ?? plusSignImage}
            alt="Add new"
          />
        </button>
        <div className={cs('mt-1', 'text-center', 'lh-sm', textClass)}>
          {label ?? '\u00A0'}
        </div>
      </div>
    );
  }
);
