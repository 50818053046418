import { Link } from 'react-router-dom';
import cs from 'classnames';

import { ArrowImage } from 'src/components/primitives';

import styles from './EventWineLink.module.scss';

interface Props {
  linkTo: string;
  title: string;
  wine: Wine;
  respectOrdering: boolean;
  status: 'complete' | 'current' | 'upcoming';
}

export function EventWineLink({
  linkTo,
  title,
  wine,
  respectOrdering,
  status,
}: Props): JSX.Element {
  return (
    <Link
      to={status !== 'upcoming' ? linkTo : ''}
      className={cs(
        'btn btn-text d-flex text-start align-items-center position-relative',
        styles.link,
        respectOrdering
          ? {
              [styles.current]: status === 'current',
              [styles.upcoming]: status === 'upcoming',
            }
          : null
      )}
    >
      <div className={cs('flex-shrink-0', styles.bottleContainer)}>
        <div className="mx-2">
          <img src={wine.bottleImage} className="img-fluid" alt="Wine bottle" />
        </div>
      </div>

      <div className="wcl-text-size-lg2 ps-2">
        <div className="fw-bold">{title}</div>
        <div>
          {wine.wineryName} {wine.name}
        </div>
      </div>
      <div className="ms-auto">
        <div className="wcl-text-size fw-semibold text-center">
          {status === 'complete' ? (
            'Complete'
          ) : status === 'current' || respectOrdering ? (
            <>
              <div>Taste</div>
              <ArrowImage width={50} className="d-block" direction="right" />
            </>
          ) : null}
        </div>
      </div>
    </Link>
  );
}
