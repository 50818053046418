import React from 'react';

import { colors } from 'src/data/colors';
import { ValuePickerWithModal } from 'src/components/primitives/ValuePickerWithModal';
import { WineColorPickerModal } from './WineColorPickerModal';

interface Props {
  wineType: Wine['type'];
  selectedColor: string | null;
  onChange: (color: string) => unknown;
}

export function WineColorPicker({ wineType, selectedColor, onChange }: Props) {
  const selected = React.useMemo(
    () => colors[wineType].find((c) => c.id === selectedColor),
    [selectedColor, wineType]
  );
  const placeholder = React.useMemo(() => colors[wineType][0], [wineType]);

  return (
    <ValuePickerWithModal
      label="Color"
      keepModalOpen={true}
      values={selectedColor ? [selectedColor] : []}
      itemsContainerClass="justify-content-center"
      onChange={(v) => onChange(v[0] ?? null)}
      numButtons={1}
      renderButton={({ ref, onClick }) => (
        <button
          className="btn p-0 shadow-none w-100"
          onClick={onClick}
          ref={ref}
        >
          <div
            className="ratio w-100"
            style={{ '--bs-aspect-ratio': '160%' } as React.CSSProperties}
          >
            <img
              src={(selected ?? placeholder).image}
              className="img-fluid rounded mh-100"
              alt={(selected ?? placeholder).name}
              style={{ opacity: selected ? 1 : 0.35 }}
            />
          </div>
          {selected ? (
            <div className="mt-1 text-center wcl-text-size-sm1 lh-sm">
              {selected?.name}
            </div>
          ) : null}
        </button>
      )}
      renderModal={({ onChange, onClose, overlayProps }) => (
        <WineColorPickerModal
          wineType={wineType}
          onClose={onClose}
          selectedColor={selectedColor}
          onToggle={(v) => onChange([v])}
          {...overlayProps}
        />
      )}
    />
  );
}
