import React from 'react';
import { useParams } from 'react-router-dom';
import { WineId, winesMap } from 'src/data/wines';

export function useWineParam() {
  const { wineId } = useParams<{ wineId: string }>();

  return React.useMemo(() => {
    const wine = winesMap[wineId! as WineId];

    if (wine == null) {
      throw Error('Wine not found');
    }

    return {
      wineId,
      wine,
    };
  }, [wineId]);
}
