import { motion } from 'framer-motion';

import { GlassImage, Props } from './GlassImage';

export function SwingingGlassImage(props: Props) {
  return (
    <motion.div
      initial={{ '--rotate': '20deg' } as any}
      animate={{ '--rotate': '-20deg' } as any}
      transition={{
        duration: 1,
        repeat: Infinity,
        repeatType: 'reverse',
      }}
      style={{ transform: 'rotate(var(--rotate))' }}
    >
      <GlassImage {...props} />
    </motion.div>
  );
}
