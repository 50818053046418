import React from 'react';
import { vibesMap } from 'src/data/vibes';

import { RoundedButton } from 'src/components/primitives/RoundedButton';

interface Props {
  vibe?: string;
  disabled?: boolean;
  onClick: () => unknown;
  inSelectedState?: boolean;
}

export const WineVibeRoundedButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ vibe, disabled, onClick, inSelectedState }: Props, ref) => {
    const selected = vibe ? vibesMap[vibe] : null;
    return (
      <RoundedButton
        ref={ref}
        size={VIBE_ROUNDED_BUTTON_SIZE}
        image={selected?.image}
        label={selected?.name}
        disabled={disabled}
        onClick={onClick}
        isSelected={inSelectedState}
      />
    );
  }
);

export const VIBE_ROUNDED_BUTTON_SIZE = 54;
