interface Props {
  header?: JSX.Element;
  children: JSX.Element;
  footer?: JSX.Element;
}

export function PageFrame({ header, children, footer }: Props): JSX.Element {
  return (
    <div className="d-flex flex-column align-items-stretch flex-grow-1 overflow-hidden">
      {header ? <div>{header}</div> : null}
      <div className="flex-grow-1 overflow-auto py-2 px-3 d-flex flex-column flex-grow-1">
        {children}
      </div>
      {footer ? <div>{footer}</div> : null}
    </div>
  );
}
