import React from 'react';
import ReactSlider from 'react-slider';
import cs from 'classnames';

import styles from './MyScoreSlider.module.scss';

import { MyScoreSliderMark } from './MyScoreSliderMark';

// got from Figma
const INITIAL_VALUE = 92;

interface Props {
  wineType: Wine['type'];
  myScore: number | null;
  onChange: (myScore: number) => unknown;
}

export function MyScoreSlider({ wineType, myScore, onChange }: Props) {
  const markersMap: { [key: number]: number } = React.useMemo(() => {
    let markers = Array.from({ length: 15 }, (_, i) => i).reduce(
      (map, index) => ({ ...map, [index + 2]: index + 86 }),
      {}
    );

    return { ...markers, 1: 83, 0: 80 };
  }, []);

  const sliderValue: number = React.useMemo(() => {
    for (const key in markersMap) {
      if (markersMap[key] === (myScore ?? INITIAL_VALUE)) {
        return parseInt(key);
      }
    }
    return 0;
  }, [myScore, markersMap]);

  const numMarkers = React.useMemo(
    () => Object.keys(markersMap).length,
    [markersMap]
  );

  return (
    <ReactSlider
      className={styles.slider}
      markClassName={''}
      thumbClassName={styles.thumb}
      trackClassName={''}
      value={sliderValue}
      marks={true}
      renderThumb={(props, state) => <div {...props} />}
      renderMark={({ className, key, ...props }) => (
        <MyScoreSliderMark
          key={key}
          scoreValue={markersMap[parseInt(key as any)]}
          isSelected={key === sliderValue}
          {...props}
        />
      )}
      renderTrack={({ className, ...props }, { index }) => {
        return (
          <div
            className={
              index === 0
                ? cs(styles['full-bar'], styles[`${wineType}-full-bar`])
                : cs(styles['empty-bar'], styles[`${wineType}-empty-bar`])
            }
            {...props}
          />
        );
      }}
      orientation="vertical"
      invert
      pearling
      max={numMarkers - 1}
      min={0}
      onChange={(v) => onChange(markersMap[v])}
    />
  );
}
