import React from 'react';
import Overlay, { type OverlayInjectedProps } from 'react-bootstrap/Overlay';

export interface SharedProps<V> {
  values: V[];
  modalPlacement?: OverlayInjectedProps['placement'];
  renderButton: (props: {
    ref: (ref: HTMLButtonElement | null) => unknown;
    disabled: boolean;
    value: V | undefined;
    onClick: () => unknown;
  }) => JSX.Element;
  renderModal: (props: {
    index: number;
    selected: V[];
    overlayProps: OverlayInjectedProps;
    onToggle: (selected: V) => unknown;
    onChange: (selected: V[]) => unknown;
    onClose: () => unknown;
  }) => JSX.Element;
}

export interface Props<V> extends SharedProps<V> {
  index: number;
  openedModal: number;
  setOpenedModal: (modalIndex: number) => unknown;
  onToggle: (value: V) => unknown;
  onChange: (value: V[]) => unknown;
}

export function ButtonWithModalOverlay<V>({
  index,
  values,
  modalPlacement = 'bottom',
  renderButton,
  renderModal,
  onToggle,
  onChange,
  openedModal,
  setOpenedModal,
}: Props<V>) {
  const value = values[index];
  const disabled = value == null && index > values.length;
  const isOpened = openedModal === index;
  const setShow = (show: boolean) => setOpenedModal(show ? index : -1);
  const [buttonRef, setButtonRef] = React.useState<HTMLButtonElement | null>(
    null
  );

  return (
    <>
      {renderButton({
        ref: setButtonRef,
        disabled,
        value,
        onClick: () => setShow(!isOpened),
      })}
      <Overlay
        target={buttonRef}
        show={isOpened}
        placement={modalPlacement}
        rootClose
        flip
        transition={false}
        onHide={() => setShow(false)}
        popperConfig={{
          modifiers: [
            {
              name: 'preventOverflow',
              options: { altAxis: true },
            },
          ],
        }}
      >
        {(overlayProps) =>
          renderModal({
            index,
            selected: values,
            onToggle,
            onChange,
            onClose: () => setShow(false),
            overlayProps,
          })
        }
      </Overlay>
    </>
  );
}
