import cs from 'classnames';
import React from 'react';

import { ButtonWithModalOverlay, SharedProps } from './ButtonWithModalOverlay';

interface Props<V> extends SharedProps<V> {
  label: string;
  numButtons: number;
  labelWidth?: number | string;
  labelContainerClass?: string;
  containerClass?: string;
  gap?: number;
  onChange: (values: V[]) => unknown;
  keepModalOpen?: boolean;
  itemsContainerClass?: string;
}

export function ValuePickerWithModal<V extends string>({
  label,
  labelWidth = '100%',
  containerClass = 'mb-3',
  labelContainerClass = 'mb-1 fw-light mx-auto',
  modalPlacement,
  gap = 2,
  values,
  onChange,
  numButtons,
  renderButton,
  renderModal,
  keepModalOpen = false,
  itemsContainerClass,
}: Props<V>) {
  const [openedModal, setOpenedModal] = React.useState<number>(-1);

  return (
    <div className={containerClass}>
      <div className={labelContainerClass}>
        <label className="text-center" style={{ width: labelWidth }}>
          {label}
        </label>
      </div>
      <div
        className={cs(
          'd-flex flex-wrap align-items-start',
          `gap-${gap}`,
          itemsContainerClass
        )}
      >
        {Array.from({ length: numButtons }, (_, i) => i).map((index) => (
          <ButtonWithModalOverlay
            openedModal={openedModal}
            setOpenedModal={setOpenedModal}
            key={index}
            index={index}
            modalPlacement={modalPlacement}
            renderButton={renderButton}
            renderModal={renderModal}
            values={values}
            onChange={onChange}
            onToggle={(value) => {
              const adding = !values.includes(value);

              const replacing =
                adding && values[index] !== undefined
                  ? values[index]
                  : undefined;

              onChange(
                replacing != null
                  ? values.map((v) => (v === replacing ? value : v))
                  : adding
                  ? [...values, value]
                  : values.filter((v) => v !== value)
              );

              if (!keepModalOpen) {
                setOpenedModal(
                  adding
                    ? index === values.length - 1
                      ? -1
                      : openedModal + 1
                    : values.length - 1
                );
              }
            }}
          />
        ))}
      </div>
    </div>
  );
}
