import Modal from 'react-bootstrap/Modal';

interface Props {
  show: boolean;
  onHide: () => unknown;
}

export function HowItWorksModal({ show, onHide }: Props) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      contentClassName="mb-5"
      dialogClassName="pb-5"
      style={{ '--bs-modal-width': 'calc(100vw - 20px)' } as any}
    >
      <Modal.Body
        className="text-center p-0 overflow-hidden"
        style={{ '--bs-modal-width': '90vw', maxHeight: '80vh' } as any}
      >
        <div className="position-relative mx-4 mt-4">
          <button
            type="button"
            className="btn-close position-absolute top-0 end-0"
            aria-label="Close"
            onClick={onHide}
          />
          <h2 className="fw-semibold mb-4">How it works</h2>
        </div>
        <div className="ratio-16x9 ratio rounded-bottom overflow-hidden">
          <iframe
            className="d-block "
            width="100%"
            height="auto"
            src="https://www.youtube-nocookie.com/embed/R4Qs2Gj-nSg"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
