import cs from 'classnames';
import React from 'react';

import styles from './MyScoreSliderMark.module.scss';

interface Props {
  scoreValue: number;
  isSelected?: boolean;
}

export const MyScoreSliderMark = React.forwardRef<HTMLSpanElement, Props>(
  ({ scoreValue, isSelected, ...other }, ref) => {
    const label = labels[scoreValue];

    return label != null ? (
      <div {...other} className={styles.container}>
        <span
          className={cs(styles.label, { [styles.labelSelected]: isSelected })}
        >
          {label}
        </span>
        <span className={styles.line} />
        <span ref={ref} className={styles.mark} />
        <span className={styles.value}>{scoreValue}</span>
      </div>
    ) : (
      <span className={styles.simpleMark} {...other} />
    );
  }
);

const labels: { [value: number]: string } = {
  80: 'Basic / spoiled',
  86: "It's not for me",
  88: 'Ok, not great',
  90: 'Distinguished',
  93: 'A cut above',
  96: 'Instant classic',
  100: 'Truly exceptional',
};
