import React from 'react';
import { useLocation } from 'react-router-dom';
import { useGoogleAuthentication } from 'src/utils/google-oauth';

import { ExternalPageShell } from 'src/components/pages-structures';
import { ConnoisseurLogo } from 'src/components/primitives';

export function LoginPage() {
  const location = useLocation();
  const [isLoading, setIsLoading] = React.useState(false);
  const startGoogleAuthentication = useGoogleAuthentication();
  const eventName = location.state?.event;

  return (
    <ExternalPageShell showTopConnoisseur={false}>
      <div className="">
        <div className="mb-5">
          <ConnoisseurLogo width={180} />
        </div>

        {eventName ? (
          <h2 className="mb-5">
            <div>Welcome to</div>
            <div className="fw-semibold">{eventName}</div>
          </h2>
        ) : null}
        <p className="wcl-text-size-lg2">Sign in to get started:</p>
        <div className="d-grid">
          <button
            className="btn btn-primary btn-lg px-5 py-3 fw-semibold fs-3"
            disabled={isLoading}
            onClick={() => {
              setIsLoading(true);
              startGoogleAuthentication({
                from: location.state?.from || '/',
              });
            }}
          >
            {isLoading ? (
              <div className="d-flex align-items-center">
                <div
                  className="spinner-border spinner-border-sm"
                  role="status"
                />
                <div className="ms-3">Redirecting to Google...</div>
              </div>
            ) : (
              'Continue with Google'
            )}
          </button>
        </div>
      </div>
    </ExternalPageShell>
  );
}
