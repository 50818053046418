import React from 'react';
import Popover from 'react-bootstrap/Popover';

import { MyScoreSlider } from './components';

interface Props {
  wineType: Wine['type'];
  myScore: number | null;
  onChange: (myScore: number) => unknown;
  onClose: () => unknown;
}

export const MyScoreModal = React.forwardRef<HTMLDivElement, Props>(
  ({ wineType, myScore, onChange, onClose, ...other }, ref) => {
    return (
      <Popover id="popover-my-score" ref={ref} {...other}>
        <Popover.Body className="wcl-text-size-lg1">
          <div className="position-relative text-center">
            <button
              onClick={onClose}
              type="button"
              className="btn-close position-absolute top-0 end-0"
              aria-label="Close"
            />
            <div>Scale</div>
            <div className="wcl-text-size-lg2 fw-bold mb-4">100-point</div>
          </div>

          <MyScoreSlider
            wineType={wineType}
            onChange={onChange}
            myScore={myScore}
          />
        </Popover.Body>
      </Popover>
    );
  }
);
