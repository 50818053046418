import { motion } from 'framer-motion';
import cs from 'classnames';

interface Props<Value> {
  className?: string;
  children: (value: Value, index: number) => JSX.Element;
  keyGetter: (value: Value) => string | number;
  values: Array<Value>;
}

export function AnimatedList<Value>({
  className,
  children,
  keyGetter,
  values,
}: Props<Value>): JSX.Element {
  return (
    <motion.ul
      initial="hidden"
      animate="visible"
      variants={{
        visible: {
          transition: {
            staggerChildren: 0.1,
          },
        },
        hidden: {},
      }}
      className={cs('d-flex flex-column list-unstyled', className)}
    >
      {values.map((value, index) => {
        const key = keyGetter(value);

        return (
          <motion.li
            key={key}
            variants={{
              visible: { opacity: 1, translateY: 0 },
              hidden: { opacity: 0, translateY: 10 },
            }}
          >
            {children(value, index)}
          </motion.li>
        );
      })}
    </motion.ul>
  );
}
