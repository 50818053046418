import { Link } from 'react-router-dom';
import { ArrowImage } from 'src/components/primitives';

import { SubmitButton } from './components/SubmitButton';

interface Props {
  pageTitle: string;
  backButtonHref?: string;
  submitButtonContent?: string | JSX.Element;
  onSubmit?: () => Promise<unknown>;
  children?: JSX.Element;
}

export function Footer({
  pageTitle,
  submitButtonContent,
  onSubmit,
  backButtonHref,
  children,
}: Props) {
  return (
    <>
      <div className="py-2 px-4 bg-light d-flex justify-content-between align-items-center">
        <div style={{ flexBasis: '84px' }}>
          {backButtonHref ? (
            <Link
              className="text-center text-decoration-none py-2 d-inline-block"
              to={backButtonHref}
            >
              <div>Back</div>
              <ArrowImage direction="left" className="d-block" width={60} />
            </Link>
          ) : null}
        </div>
        <div className="flex-grow-1 text-center">
          <div className="wcl-text-size-lg1 fw-bold">{pageTitle}</div>
          {children}
        </div>

        <div
          className="d-flex justify-content-end"
          style={{ flexBasis: '96px' }}
        >
          {submitButtonContent != null && onSubmit != null ? (
            <SubmitButton onSubmit={onSubmit}>
              {submitButtonContent}
            </SubmitButton>
          ) : null}
        </div>
      </div>
    </>
  );
}
