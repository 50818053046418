interface Props {
  width?: string | number;
  className?: string;
  direction: 'left' | 'right';
}

export function ArrowImage({ width = '100%', className, direction }: Props) {
  return (
    <svg
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 13"
      className={className}
      style={
        direction === 'right'
          ? {
              transform: 'rotate(180deg)',
            }
          : undefined
      }
    >
      <polygon points="80,6 1.9,6 7.2,0.7 6.5,0 0,6.5 6.5,13 7.2,12.2 1.9,7 80,7 80,6 " />
    </svg>
  );
}
