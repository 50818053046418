import { motion } from 'framer-motion';

interface Props {
  children: JSX.Element;
  className?: string;
}

export function MotionDownFadeInDiv(props: Props): JSX.Element {
  return (
    <motion.div
      initial={{ opacity: 0, translateY: -5 }}
      animate={{ translateY: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      {...props}
    />
  );
}
