import { useQueryClient, UseQueryOptions } from 'react-query';
import { URLS } from 'src/config';

import { useDataMutation, useDataQuery } from 'src/utils';
import { useUserStore } from 'src/utils/auth/stores';
import { TastingNote, WriteTastingNote } from './types';

export function useEventTastingNoteCreateOrUpdate() {
  const queryClient = useQueryClient();

  return useDataMutation(
    (axios, urls) => (tn: WriteTastingNote) => {
      let { flavors, ...payload } = tn;

      return axios.post(
        `${urls.EVENTS.TASTING_NOTES}${tn.id ? `/${tn.id}` : ''}`,
        // workaround Xano's bug
        { ...payload, _flavors: flavors }
      );
    },
    {
      onSuccess: (response, tn) => {
        queryClient.invalidateQueries({
          queryKey: [URLS.EVENTS.TASTING_NOTES],
        });
      },
    }
  );
}

export function useEventTastingNotes(
  eventId: string,
  params: Record<string, any> = {},
  opts: UseQueryOptions<
    unknown,
    unknown,
    any,
    [url: string, params?: object]
  > = {}
) {
  return useDataQuery<Array<TastingNote>>(
    [(urls) => urls.EVENTS.TASTING_NOTES, { ...params, event_id: eventId }],
    { suspense: true, ...opts }
  );
}

export function useMyEventTastingNotes(eventId: string) {
  const user_id = useUserStore((state) => state.user!.id);
  return useEventTastingNotes(eventId, { user_id });
}
