import { useUser } from 'src/utils/auth/stores';
import { UserAvatar } from 'src/components/primitives';
import { motion } from 'framer-motion';

export function WelcomeUser(): JSX.Element {
  const user = useUser();
  const firstName = user?.name?.split(' ')[0];

  return (
    <motion.div
      initial={{ translateY: -10, opacity: 0 }}
      animate={{ translateY: 0, opacity: 1 }}
      //transition={{ delay: 0, duration: 1.75 }}
      className="d-flex align-items-center justify-content-center gap-3"
    >
      <h2 className="text-center fw-light mb-0">Welcome, {firstName}!</h2>
      <UserAvatar user={user!} />
    </motion.div>
  );
}
