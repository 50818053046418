import { Header } from 'src/features/events/pages/EventPage/components';
import {
  MotionDownFadeInDiv,
  SwingingGlassImage,
} from 'src/components/primitives';

import { PageFrame } from './PageFrame';

export function LoadingPage(): JSX.Element {
  return (
    <PageFrame header={<Header />}>
      <MotionDownFadeInDiv className="d-flex flex-column align-items-center justify-content-center pb-5 flex-grow-1">
        <>
          <SwingingGlassImage />
          <h1 className="mb-5 mt-2">Loading...</h1>
        </>
      </MotionDownFadeInDiv>
    </PageFrame>
  );
}
