import React from 'react';
import type { OverlayInjectedProps } from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';

import {
  flavors,
  WineFlavorGroupWithFlavors,
  WineFlavorKey,
} from 'src/data/flavors';

import { FlavorRoundedButton } from './components/FlavorRoundedButton';

interface Props extends OverlayInjectedProps {
  wineType: Wine['type'];
  selected: Array<WineFlavorKey>;
  onToggle: (value: WineFlavorKey) => unknown;
  onClose: () => unknown;
  index: number;
}

export const WineFlavorPickerModal = React.forwardRef<HTMLDivElement, Props>(
  ({ wineType, onToggle, selected, onClose, index, ...overlayProps }, ref) => {
    const availableGroups = React.useMemo(() => flavors[wineType], [wineType]);

    const [selectedGroup, selectGroup] = React.useState<
      WineFlavorGroupWithFlavors | undefined
    >(() => {
      return selected[index] != null
        ? availableGroups.find((group) =>
            group.flavors.map((f) => f.id).includes(selected[index])
          )
        : undefined;
    });

    const reversedFlavors = React.useMemo(
      () => [...(selectedGroup?.flavors ?? [])].reverse(),
      [selectedGroup?.flavors]
    );

    React.useEffect(() => {
      // re-position modal on content change
      overlayProps.popper?.scheduleUpdate?.();
    }, [selectedGroup?.id, overlayProps.popper]);

    return (
      <Popover id={`popover-wine-flavor-${index}`} {...overlayProps} ref={ref}>
        <Popover.Body className="text-center">
          <div className="position-relative mb-4">
            <button
              onClick={() =>
                selectedGroup == null ? onClose() : selectGroup(undefined)
              }
              type="button"
              className="btn-close position-absolute top-0 end-0"
              aria-label="Close"
            />
            <div className="mx-4 px-1">
              {selectedGroup == null ? (
                'Pick a flavor group to open details'
              ) : (
                <div>
                  <img
                    src={
                      selectedGroup.typeImages?.[wineType] ??
                      selectedGroup.image
                    }
                    alt={selectedGroup.name}
                    className="img-fluid wcl-image-shadow mb-1"
                    style={{ maxWidth: 100 }}
                  />
                  <div className="fw-bold wcl-text-size-lg2">
                    {selectedGroup.name}
                  </div>
                  <div>What's your flavor?</div>
                </div>
              )}
            </div>
          </div>
          {selectedGroup != null ? (
            <div className="d-flex flex-wrap-reverse justify-content-center gap-2 flex-row-reverse">
              {reversedFlavors.map((flavor) => {
                const isSelected = selected.includes(flavor.id);
                //const isDisabled = disabledFlavors.includes(flavor.id);
                return (
                  <div key={flavor.id} style={{ flexBasis: '18%' }}>
                    <FlavorRoundedButton
                      inSelectedState={isSelected}
                      flavor={flavor.id}
                      onClick={() => onToggle(flavor.id)}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="d-flex gap-1 justify-content-center align-items-start fw-light flex-wrap">
              {availableGroups.map((group) => (
                <div
                  key={group.id}
                  style={{ flexBasis: '30%' }}
                  className="d-flex flex-column"
                >
                  <button
                    className="btn btn-link px-1 pb-2"
                    onClick={() => selectGroup(group)}
                    style={{ height: 70 }}
                  >
                    <img
                      src={group.typeImages?.[wineType] ?? group.image}
                      alt={group.name}
                      className="img-fluid wcl-image-shadow"
                      // ensure the popover is re-positioned after the image is loaded
                      onLoad={() => overlayProps.popper?.scheduleUpdate?.()}
                    />
                  </button>
                  <div className="wcl-text-size-sm1">{group.name}</div>
                </div>
              ))}
            </div>
          )}
        </Popover.Body>
      </Popover>
    );
  }
);
