import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface AuthTokenStoreState {
  authToken: string | null;
  setAuthToken: (authToken: string | null) => void;
}

export const useAuthTokenStore = create<AuthTokenStoreState>()(
  persist(
    (set) => ({
      authToken: null,
      setAuthToken: (authToken: string | null) => set({ authToken }),
    }),
    { version: 1, name: 'auth-token' }
  )
);

interface UserStoreState {
  user: null | CurrentUser;
  setUser: (user: CurrentUser) => void;
  removeUser: () => void;
}

export const INITIAL_USER_STATE =
  process.env.REACT_APP_USE_FAKE_USER === '1'
    ? {
        name: 'Will van der Vaart',
        id: 'will',
        email: 'will.vandervaart@gmail.com',
        profile_image:
          'https://lh3.googleusercontent.com/a/AEdFTp7RFHCf0x0FZOL_9HNVGAD83e5dqC_9tJ9qNUlQGw=s96-c',
      }
    : undefined;

export const useUserStore = create<UserStoreState>()((set) => ({
  user: INITIAL_USER_STATE ?? null,
  setUser: (user: CurrentUser) => set({ user }),
  removeUser: () => set({ user: null }),
}));

export const useUser = () => useUserStore((s) => s.user);
