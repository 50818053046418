import { winesMap } from 'src/data/wines';
import { PageFrame } from 'src/components/pages-structures';
import type { TastingNote } from 'src/features/tasting-notes/types';

import { Header, WelcomeUser, EventWineLink } from './components';
import React from 'react';
import { useMyEventTastingNotes } from 'src/features/tasting-notes/tasting-notes-queries';
import { useEventParam } from 'src/features/events/use-event-param-hook';
import { AnimatedList } from 'src/components/primitives';

export const EventPage = (): JSX.Element => {
  const { event, eventId } = useEventParam(true);
  const tastingNotes = useMyEventTastingNotes(event!.id).data!;

  const eventWines:
    | undefined
    | Array<{
        title: string;
        wine: Wine;
        tastingNote?: TastingNote;
      }> = React.useMemo(() => {
    if (tastingNotes == null) {
      return [];
    }

    return event.wines.map(({ title, wineId }) => ({
      title,
      wine: winesMap[wineId],
      tastingNote: tastingNotes.find(
        ({ wine_id, event_id }) => wine_id === wineId && event_id === eventId
      ),
    }));
  }, [event.wines, tastingNotes, eventId]);

  return (
    <PageFrame header={<Header />}>
      <div className="px-4">
        <WelcomeUser />
        {event == null ? (
          <h2 className="text-center text-danger mt-5">Event not found</h2>
        ) : (
          <>
            <h2 className="mt-4 mb-3 text-center">
              Today's plan:{' '}
              <span className="d-inline-block fw-semibold">{event.name}</span>
            </h2>
            {eventWines != null ? (
              <AnimatedList
                className="mt-4 gap-4"
                values={eventWines}
                keyGetter={({ wine }) => wine.id}
              >
                {({ wine, title, tastingNote }, index) => {
                  const prev = eventWines[index - 1];
                  let previousWasCompleted =
                    prev != null ? prev.tastingNote != null : true;
                  const isCurrent = previousWasCompleted && tastingNote == null;
                  const upcoming = !previousWasCompleted && !isCurrent;
                  previousWasCompleted = tastingNote != null;
                  const respectOrdering =
                    // @ts-ignore respectOrder doesn't exist but may exist in the future
                    event.respectOrdering ?? event.type === 'regular';

                  return (
                    <EventWineLink
                      key={wine.id}
                      linkTo={`/event/${event.id}/tasting-notes/${wine.id}`}
                      title={title}
                      wine={wine}
                      respectOrdering={respectOrdering}
                      status={
                        !respectOrdering
                          ? tastingNote
                            ? 'complete'
                            : 'current'
                          : isCurrent
                          ? 'current'
                          : upcoming
                          ? 'upcoming'
                          : 'complete'
                      }
                    />
                  );
                }}
              </AnimatedList>
            ) : null}
          </>
        )}
      </div>
    </PageFrame>
  );
};
