import React from 'react';

import { flavorsMap } from 'src/data/flavors';
import { RoundedButton } from 'src/components/primitives/RoundedButton';

interface Props {
  flavor?: keyof typeof flavorsMap;
  disabled?: boolean;
  onClick: () => unknown;
  inSelectedState?: boolean;
}

export const FlavorRoundedButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ flavor, disabled, onClick, inSelectedState }, ref) => {
    const selected = flavor ? flavorsMap[flavor] : null;
    return (
      <RoundedButton
        ref={ref}
        size={FLAVOR_ROUNDED_BUTTON_SIZE}
        image={selected?.image}
        label={selected?.name}
        disabled={disabled}
        onClick={onClick}
        isSelected={inSelectedState}
      />
    );
  }
);

export const FLAVOR_ROUNDED_BUTTON_SIZE = 48;
