import { QueryClient } from 'react-query';

export function createQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        staleTime: Infinity,
        // queryFn: ({ queryKey }) => {
        //   const { url, params } = buildQueryURL(queryKey);
        //   return axios.get(url, { params }).then((response) => response.data);
        // },
      },
    },
  });
}

// function buildQueryURL(queryKey: readonly unknown[]): {
//   url: string;
//   params: object;
// } {
//   return queryKey.reduce(
//     ({ url, params }, part) => {
//       if (part == null) {
//         return { url, params };
//       }
//       if (typeof part === 'object') {
//         params = { ...params, ...part };
//       } else {
//         url += `${url !== '' ? '/' : ''}${part}`;
//       }

//       return { url, params };
//     },
//     {
//       url: '',
//       params: {},
//     } as {
//       url: string;
//       params: object;
//     }
//   );
// }
