import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';

import { useAuthTokenStore, useUserStore, INITIAL_USER_STATE } from './stores';
import { URLS } from 'src/config';

export function useUserAuthState() {
  const { setAuthToken, authToken } = useAuthTokenStore();
  const userStore = useUserStore();

  React.useEffect(() => {
    // keep user synced with authToken
    if (authToken == null && userStore.user != null) {
      userStore.removeUser();
    }
  }, [authToken, userStore]);

  return useQuery({
    queryKey: [URLS.AUTH.AUTH_CURRENT_USER, { authtoken: authToken }] as const,
    queryFn: ({ queryKey: [url, params] }) => {
      return axios.get(url, { params }).then(
        (response) => {
          const user = response.data;
          userStore.setUser(user);
          return user;
        },
        (err) => {
          if (err.response?.status === 401) {
            setAuthToken(null);
          }
          return Promise.reject(err);
        }
      );
    },
    enabled: authToken != null,
    staleTime: Infinity,
    initialData: INITIAL_USER_STATE ? { user: INITIAL_USER_STATE } : undefined,
  });
}
