import React from 'react';
import Popover, { PopoverProps } from 'react-bootstrap/Popover';
import cs from 'classnames';

import { colors } from 'src/data/colors';

import styles from './WineColorPickerModal.module.scss';

interface Props extends Omit<PopoverProps, 'onChange'> {
  wineType: Wine['type'];
  onClose: () => unknown;
  selectedColor: string | null;
  onToggle: (color: string) => unknown;
}

export const WineColorPickerModal = React.forwardRef<HTMLDivElement, Props>(
  ({ wineType, onClose, selectedColor, onToggle, ...other }, ref) => {
    return (
      <Popover id="popover-wine-color" {...other} ref={ref}>
        <Popover.Body className="text-center" style={{ maxWidth: 320 }}>
          <div className="position-relative">
            <button
              onClick={onClose}
              type="button"
              className="btn-close position-absolute top-0 end-0"
              aria-label="Close"
            />
            <div className="mx-4 px-1 mb-4">What's in your glass?</div>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              {colors[wineType].map((color, i) => {
                const isSelected = color.id === selectedColor;
                return (
                  <div
                    key={color.id}
                    style={{ flexBasis: '33%' }}
                    className={cs('d-flex', { 'border-start': i % 3 > 0 })}
                  >
                    <button
                      className={cs(
                        'btn p-2 flex-grow-1',
                        isSelected ? styles.selected : 'shadow-none'
                      )}
                      onClick={() => onToggle(color.id)}
                    >
                      <img
                        style={{ maxHeight: 60 }}
                        src={color.image}
                        className="img-fluid rounded"
                        alt={color.name}
                        onLoad={() => other.popper?.scheduleUpdate?.()}
                      />
                      <div className="wcl-text-size-sm1">{color.name}</div>
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  }
);
