import { AxiosError } from 'axios';

import { PageFrame } from 'src/components/pages-structures';
import { Header } from 'src/features/events/pages/EventPage/components';

interface Props {
  error: AxiosError | Error;
  onTryAgain: () => unknown;
}

export function ShowErrorPage({ error, onTryAgain }: Props): JSX.Element {
  const targetURL = (error as any).response?.config?.url;

  return (
    <PageFrame header={<Header />}>
      <div className="pt-5 mx-5">
        <h2 className="text-center">
          Ops, something went wrong and the page couldn't be loaded.
        </h2>
        <div className="text-center my-4">
          <button
            className="btn btn-primary btn-lg mx-auto px-5"
            onClick={onTryAgain}
          >
            Try again
          </button>
        </div>
        <hr />
        <div>
          <div>
            <div className="fw-semibold">Error details:</div>
            <span className="d-inline-block">
              {error.name}: {error.message}
              {targetURL ? (
                <div className="mt-3">
                  <div className="fw-semibold">Loading:</div>
                  <div>{(error as any).response?.config?.url}</div>
                </div>
              ) : null}
            </span>
          </div>
        </div>
      </div>
    </PageFrame>
  );
}
