interface Props {
  bottleImage: string;
}

export function BottleImage({ bottleImage }: Props) {
  return (
    <img
      src={bottleImage}
      alt=""
      className="img-fluid"
      style={{ maxHeight: '60vh' }}
    />
  );
}
