import React from 'react';
import { useParams } from 'react-router-dom';
import { events } from 'src/data/events';

export function useEventParam(throwIfNotFound: true): {
  event: (typeof events)[number];
  eventId: string;
};
export function useEventParam(throwIfNotFound: false): {
  event?: (typeof events)[number];
  eventId?: string;
};
export function useEventParam(throwIfNotFound = true) {
  const { eventId } = useParams<{ eventId: string }>();

  return React.useMemo(() => {
    const event = events.find((event) => event.id === eventId);

    if (event == null && throwIfNotFound) {
      throw Error('Event not found');
    }

    return {
      event,
      eventId,
    };
  }, [eventId, throwIfNotFound]);
}
