import React from 'react';

interface Props {
  onSubmit: () => Promise<unknown>;
  children: string | JSX.Element;
}

export function SubmitButton({ children, onSubmit }: Props) {
  const [sending, setSending] = React.useState(false);

  return (
    <>
      <button
        type="button"
        onClick={() => {
          setSending(true);
          onSubmit().finally(() => setSending(false));
        }}
        className="btn btn btn-dark fw-bold px-3"
      >
        {sending ? (
          <span
            className="spinner-border spinner-border-sm me-2"
            role="status"
            aria-hidden="true"
          ></span>
        ) : null}
        {children}
      </button>
    </>
  );
}
