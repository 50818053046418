import cs from 'classnames';
import Modal from 'react-bootstrap/Modal';

import styles from './ExternalPageShell.module.scss';
import {
  ConnoisseurLogo,
  MotionDownFadeInDiv,
} from 'src/components/primitives';

interface Props {
  showTopConnoisseur?: boolean;
  children: React.ReactElement;
  title?: string | React.ReactElement;
}

export function ExternalPageShell({
  showTopConnoisseur = true,
  children,
  title,
}: Props): JSX.Element {
  return (
    <div
      className={cs(
        styles.container,
        'bg-light d-flex flex-column align-items-stretch flex-grow-1 justify-content-center'
      )}
    >
      {showTopConnoisseur ? (
        <div className={cs(styles.topLogoContainer, 'position-absolute')}>
          <ConnoisseurLogo color="white" width={150} shadow />
        </div>
      ) : null}
      <div className="modal position-static d-block">
        <div className={cs('modal-dialog modal-dialog-centered')}>
          <MotionDownFadeInDiv className="modal-content py-5">
            <Modal.Body className="text-center">
              {title == null ? null : typeof title === 'string' ? (
                <h3 className={cs(styles.titleContainer, 'mb-4')}>{title}</h3>
              ) : (
                title
              )}
              {children}
            </Modal.Body>
          </MotionDownFadeInDiv>
        </div>
      </div>
    </div>
  );
}

interface LoadingIndicatorProps {
  children: React.ReactNode;
}

ExternalPageShell.LoadingIndicator = function LoadingIndicator({
  children,
}: LoadingIndicatorProps): JSX.Element {
  return (
    <h2 className="mb-0">
      <div
        className="spinner-border"
        role="status"
        style={
          { '--bs-spinner-border-width': '0.25rem' } as React.CSSProperties
        }
      />
      <span className="ms-3">{children}</span>
    </h2>
  );
};
