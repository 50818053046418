import { useEffect } from 'react';

// disable zoom on iOS when focusing on a text field
// https://stackoverflow.com/a/57527009/780262
function addMaximumScaleToMetaViewport() {
  const el = document.querySelector('meta[name=viewport]');

  if (el !== null) {
    let content = el.getAttribute('content');

    if (content) {
      let re = /maximum-scale=[0-9.]+/g;

      if (re.test(content)) {
        content = content.replace(re, 'maximum-scale=1.0');
      } else {
        content = [content, 'maximum-scale=1.0'].join(', ');
      }

      el.setAttribute('content', content);
    }
  }
}

// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
const checkIsIOS = () =>
  /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

// hook to disable zoom on iOS when focusing on a text field
export function useDisableIosTextFieldZoom() {
  useEffect(() => {
    if (checkIsIOS()) {
      addMaximumScaleToMetaViewport();
    }
  }, []);
}
