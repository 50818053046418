interface Props {
  color?: string;
  width?: number | string;
  shadow?: boolean;
  className?: string;
}

export function ConnoisseurLogo({
  color = '#222222',
  width = 250,
  shadow = false,
  className = '',
}: Props) {
  return (
    <svg
      width={width}
      viewBox="0 0 208 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={
        shadow
          ? { filter: 'drop-shadow(1px 1px 2px rgb(0 0 0 / 0.5))' }
          : undefined
      }
      className={className}
    >
      <g fill={color}>
        <path d="M10.5699 0.833008C4.64839 0.833008 0 5.21493 0 10.9588C0 16.7323 4.64839 21.0846 10.5403 21.0846C14.4781 21.0846 17.7349 19.1601 19.5114 15.8441L15.6328 13.209C14.4485 15.2223 12.8497 16.4066 10.5403 16.4066C7.4315 16.4066 5.21093 14.2749 5.21093 10.9884C5.21093 7.70197 7.4315 5.51101 10.5403 5.51101C12.8497 5.51101 14.4485 6.69531 15.6328 8.70863L19.5114 6.07355C17.7349 2.7575 14.4781 0.833008 10.5699 0.833008Z" />
        <path d="M31.7622 0.833008C25.9295 0.833008 21.2515 5.21493 21.2515 10.9588C21.2515 16.7323 25.9295 21.0846 31.7622 21.0846C37.5949 21.0846 42.3321 16.7323 42.3321 10.9588C42.3321 5.21493 37.5949 0.833008 31.7622 0.833008ZM31.7918 5.51101C34.8118 5.51101 37.1212 7.70197 37.1212 10.9588C37.1212 14.2749 34.8118 16.4066 31.7918 16.4066C28.6534 16.4066 26.4625 14.2749 26.4625 10.9588C26.4625 7.70197 28.6534 5.51101 31.7918 5.51101Z" />
        <path d="M57.4725 1.1883V10.9588L46.873 0.833008H45.2446V20.7293H50.3371V10.8108L60.9366 21.0846H62.565V1.1883H57.4725Z" />
        <path d="M78.6951 1.1883V10.9588L68.0956 0.833008H66.4672V20.7293H71.5597V10.8108L82.1592 21.0846H83.7876V1.1883H78.6951Z" />
        <path d="M97.1938 0.833008C91.3611 0.833008 86.6831 5.21493 86.6831 10.9588C86.6831 16.7323 91.3611 21.0846 97.1938 21.0846C103.027 21.0846 107.764 16.7323 107.764 10.9588C107.764 5.21493 103.027 0.833008 97.1938 0.833008ZM97.2235 5.51101C100.243 5.51101 102.553 7.70197 102.553 10.9588C102.553 14.2749 100.243 16.4066 97.2235 16.4066C94.0851 16.4066 91.8941 14.2749 91.8941 10.9588C91.8941 7.70197 94.0851 5.51101 97.2235 5.51101Z" />
        <path d="M126.093 0.833008C122.126 0.833008 119.254 3.11279 119.254 6.60649C119.254 9.35999 121.089 11.1364 124.198 12.4984L126.212 13.3866C127.574 13.9788 128.195 14.2749 128.195 15.1927C128.195 16.1401 127.544 16.6139 126.182 16.6139C123.991 16.6139 122.777 15.3703 121.83 14.0972L118.395 16.7027C119.787 19.3378 122.599 21.0846 126.182 21.0846C130.209 21.0846 133.347 18.8344 133.347 14.9854C133.347 12.1135 131.6 10.4555 128.343 9.09352L126.093 8.14608C124.879 7.64275 124.406 7.28746 124.406 6.54727C124.406 5.65905 125.146 5.30375 126.152 5.30375C127.633 5.30375 128.491 6.07355 129.38 7.37628L132.784 4.77082C131.659 2.52064 129.498 0.833008 126.093 0.833008Z" />
        <path d="M142.516 0.833008C138.549 0.833008 135.677 3.11279 135.677 6.60649C135.677 9.35999 137.512 11.1364 140.621 12.4984L142.635 13.3866C143.996 13.9788 144.618 14.2749 144.618 15.1927C144.618 16.1401 143.967 16.6139 142.605 16.6139C140.414 16.6139 139.2 15.3703 138.253 14.0972L134.818 16.7027C136.21 19.3378 139.022 21.0846 142.605 21.0846C146.632 21.0846 149.77 18.8344 149.77 14.9854C149.77 12.1135 148.023 10.4555 144.766 9.09352L142.516 8.14608C141.302 7.64275 140.828 7.28746 140.828 6.54727C140.828 5.65905 141.569 5.30375 142.575 5.30375C144.056 5.30375 144.914 6.07355 145.803 7.37628L149.207 4.77082C148.082 2.52064 145.921 0.833008 142.516 0.833008Z" />
        <path d="M166.219 1.1883H152.659V20.7293H166.574V16.1401H157.751V12.7353H163.791V8.47177H157.751V5.77748H166.219V1.1883Z" />
        <path d="M186.919 1.1883H181.827V12.8241C181.827 15.0743 180.346 16.4066 178.274 16.4066C176.201 16.4066 174.721 15.0743 174.721 12.8241V1.1883H169.628V12.4392C169.628 17.6797 173.3 21.0846 178.274 21.0846C183.218 21.0846 186.919 17.6797 186.919 12.4392V1.1883Z" />
        <path d="M208 20.7293L202.848 13.4754C204.743 12.3208 205.927 10.3667 205.927 7.90922C205.927 3.76416 202.671 1.1883 198.378 1.1883H190.709V20.7293H195.802V14.6597H197.904L202.315 20.7293H208ZM195.802 5.62944H198.407C199.828 5.62944 200.746 6.54727 200.746 7.90922C200.746 9.30078 199.828 10.2186 198.407 10.2186H195.802V5.62944Z" />
        <path d="M114.543 20.7253H113.084H112.999H111.54C109.982 20.7007 110.085 20.1775 110.085 20.1775C110.085 20.1775 110.076 8.4538 110.085 7.48923C110.095 6.52443 111.116 5.96808 111.473 5.70661C111.829 5.44513 111.849 5.15883 111.849 5.15883L111.949 1.02204V0.34355C111.949 -0.0163636 112.157 0.000115983 112.157 0.000115983H113.927C113.927 0.000115983 114.135 -0.015921 114.135 0.34355V1.02204L114.234 5.15883C114.234 5.15883 114.253 5.44514 114.611 5.70661C114.967 5.96806 115.988 6.52443 115.998 7.48923C116.008 8.45335 115.998 20.1775 115.998 20.1775C115.998 20.1775 116.101 20.7007 114.543 20.7253Z" />
      </g>
    </svg>
  );
}
