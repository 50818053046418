export type WineFlavorKey = keyof typeof flavorsMap;

export type WineFlavorGroupKey = keyof typeof groupsMap;

export interface WineFlavor {
  id: WineFlavorKey;
  image: string;
  name: string;
}
export interface WineFlavorGroup {
  id: WineFlavorGroupKey;
  name: string;
  image: string;
  typeImages?: Partial<{ [wineType in Wine['type']]: string }>;
}

export interface WineFlavorGroupWithFlavors extends WineFlavorGroup {
  flavors: WineFlavor[];
}

export const maxSelectedFlavors = 5;

// map of flavor groups
export const groupsMap = {
  citrus: {
    name: 'Citrus',
    image: require('./images/flavor-groups/citrus.png'),
  },
  orchard: {
    name: 'Orchard',
    image: require('./images/flavor-groups/orchard.png'),
  },
  tropical: {
    name: 'Tropical',
    image: require('./images/flavor-groups/tropical.png'),
  },
  'floral-and-herbal': {
    name: 'Floral & Herbal',
    image: require('./images/flavor-groups/floral-and-herbal.png'),
    typeImages: {
      white: require('./images/flavor-groups/floral-and-herbal-white.png'),
    },
  },
  'baking-and-spices': {
    name: 'Baking & Spices',
    image: require('./images/flavor-groups/baking-and-spices-white.png'),
    typeImages: {
      red: require('./images/flavor-groups/baking-and-spices-red.png'),
    },
  },
  'berries-and-tropical-fruit': {
    name: 'Berries & Tropical Fruit',
    image: require('./images/flavor-groups/berries-and-tropical-fruit.png'),
  },
  'mineral-and-others': {
    name: 'Mineral & Others',
    image: require('./images/flavor-groups/mineral-and-others.png'),
  },
  'red-fruit': {
    name: 'Red Fruit',
    image: require('./images/flavor-groups/red-fruit.png'),
  },
  'red-and-blue-fruit': {
    name: 'Red & Blue Fruit',
    image: require('./images/flavor-groups/red-and-blue-fruit.png'),
  },
  herbal: {
    name: 'Herbal',
    image: require('./images/flavor-groups/herbal.png'),
    typeImages: { red: require('./images/flavor-groups/herbal-red.png') },
  },
  'dark-fruits': {
    name: 'Black / Dark Fruits',
    image: require('./images/flavor-groups/dark-fruits.png'),
  },
  'other-fruits-and-floral': {
    name: 'Other Fruits & Flowers',
    image: require('./images/flavor-groups/other-fruits-and-floral.png'),
  },
  'oak-smoke-and-other': {
    name: 'Oak, Smoke & Other',
    image: require('./images/flavor-groups/oak-smoke-and-other.png'),
  },
  summer: {
    name: 'Summer',
    image: require('./images/flavor-groups/summer.png'),
  },
} satisfies { [key: string]: Omit<WineFlavorGroup, 'id'> };

export const flavorsMap = {
  almond: {
    name: 'Almond',
    image: require('./images/flavors/almond.png'),
  },
  apricot: {
    name: 'Apricot',
    image: require('./images/flavors/apricot.png'),
  },
  'bacon-game': {
    name: 'Bacon / Game',
    image: require('./images/flavors/bacon-game.png'),
  },
  balsamic: {
    name: 'Balsamic',
    image: require('./images/flavors/balsamic.png'),
  },
  banana: {
    name: 'Banana',
    image: require('./images/flavors/banana.png'),
  },
  biscuit: {
    name: 'Biscuit',
    image: require('./images/flavors/biscuit.png'),
  },
  'black-cherry': {
    name: 'Black Cherry',
    image: require('./images/flavors/black-cherry.png'),
  },
  'black-currant': {
    name: 'Black Currant',
    image: require('./images/flavors/black-currant.png'),
  },
  'black-olive': {
    name: 'Black Olive',
    image: require('./images/flavors/black-olive.png'),
  },
  'black-pepper': {
    name: 'Black Pepper',
    image: require('./images/flavors/black-pepper.png'),
  },
  'black-tea': {
    name: 'Black Tea',
    image: require('./images/flavors/black-tea.png'),
  },
  blackberry: {
    name: 'Blackberry',
    image: require('./images/flavors/blackberry.png'),
  },
  blueberry: {
    name: 'Blueberry',
    image: require('./images/flavors/blueberry.png'),
  },
  butter: {
    name: 'Butter',
    image: require('./images/flavors/butter.png'),
  },
  butterscotch: {
    name: 'Butterscotch',
    image: require('./images/flavors/butterscotch.png'),
  },
  cantaloupe: {
    name: 'Cantaloupe',
    image: require('./images/flavors/cantaloupe.png'),
  },
  caramel: {
    name: 'Caramel',
    image: require('./images/flavors/caramel.png'),
  },
  cedar: {
    name: 'Cedar',
    image: require('./images/flavors/cedar.png'),
  },
  chalk: {
    name: 'Chalk',
    image: require('./images/flavors/chalk.png'),
  },
  chamomile: {
    name: 'Chamomile',
    image: require('./images/flavors/chamomile.png'),
  },
  chocolate: {
    name: 'Chocolate',
    image: require('./images/flavors/chocolate.png'),
  },
  'cigar-tobacco': {
    name: 'Cigar / Tobacco',
    image: require('./images/flavors/cigar-tobacco.png'),
  },
  cinnamon: {
    name: 'Cinnamon',
    image: require('./images/flavors/cinnamon.png'),
  },
  cloves: {
    name: 'Cloves',
    image: require('./images/flavors/cloves.png'),
  },
  cocoa: {
    name: 'Cocoa',
    image: require('./images/flavors/cocoa.png'),
  },
  coffee: {
    name: 'Coffee',
    image: require('./images/flavors/coffee.png'),
  },
  cola: {
    name: 'Cola',
    image: require('./images/flavors/cola.png'),
  },
  'cotton-candy': {
    name: 'Cotton Candy',
    image: require('./images/flavors/cotton-candy.png'),
  },
  cranberry: {
    name: 'Cranberry',
    image: require('./images/flavors/cranberry.png'),
  },
  'creme-brulee': {
    name: 'Creme Brulee',
    image: require('./images/flavors/creme-brulee.png'),
  },
  'dirt-earth': {
    name: 'Dirt / Earth',
    image: require('./images/flavors/dirt-earth.png'),
  },
  eucalyptus: {
    name: 'Eucalyptus',
    image: require('./images/flavors/eucalyptus.png'),
  },
  fig: {
    name: 'Fig',
    image: require('./images/flavors/fig.png'),
  },
  flint: {
    name: 'Flint',
    image: require('./images/flavors/flint.png'),
  },
  flowers: {
    name: 'Flowers',
    image: require('./images/flavors/flowers.png'),
  },
  'forest-floor': {
    name: 'Forest Floor',
    image: require('./images/flavors/forest-floor.png'),
  },
  ginger: {
    name: 'Ginger',
    image: require('./images/flavors/ginger.png'),
  },
  'golden-apple': {
    name: 'Golden Apple',
    image: require('./images/flavors/golden-apple.png'),
  },
  grapefruit: {
    name: 'Grapefruit',
    image: require('./images/flavors/grapefruit.png'),
  },
  'grapefruit-zest': {
    name: 'Grapefruit Zest',
    image: require('./images/flavors/grapefruit-zest.png'),
  },
  graphite: {
    name: 'Graphite',
    image: require('./images/flavors/graphite.png'),
  },
  grass: {
    name: 'Grass',
    image: require('./images/flavors/grass.png'),
  },
  'green-apple': {
    name: 'Green Apple',
    image: require('./images/flavors/green-apple.png'),
  },
  'green-olives': {
    name: 'Green Olives',
    image: require('./images/flavors/green-olives.png'),
  },
  'green-pepper': {
    name: 'Green Pepper',
    image: require('./images/flavors/green-pepper.png'),
  },
  guava: {
    name: 'Guava',
    image: require('./images/flavors/guava.png'),
  },
  'hay-straw': {
    name: 'Hay / Straw',
    image: require('./images/flavors/hay-straw.png'),
  },
  hazelnut: {
    name: 'Hazelnut',
    image: require('./images/flavors/hazelnut.png'),
  },
  honey: {
    name: 'Honey',
    image: require('./images/flavors/honey.png'),
  },
  honeysuckle: {
    name: 'Honeysuckle',
    image: require('./images/flavors/honeysuckle.png'),
  },
  jasmine: {
    name: 'Jasmine',
    image: require('./images/flavors/jasmine.png'),
  },
  kerosene: {
    name: 'Kerosene',
    image: require('./images/flavors/kerosene.png'),
  },
  kiwi: {
    name: 'Kiwi',
    image: require('./images/flavors/kiwi.png'),
  },
  lavender: {
    name: 'Lavender',
    image: require('./images/flavors/lavender.png'),
  },
  leather: {
    name: 'Leather',
    image: require('./images/flavors/leather.png'),
  },
  lemon: {
    name: 'Lemon',
    image: require('./images/flavors/lemon.png'),
  },
  'lemon-curd': {
    name: 'Lemon Curd',
    image: require('./images/flavors/lemon-curd.png'),
  },
  lemongrass: {
    name: 'Lemongrass',
    image: require('./images/flavors/lemongrass.png'),
  },
  'lemon-zest': {
    name: 'Lemon Zest',
    image: require('./images/flavors/lemon-zest.png'),
  },
  'licorice-anise': {
    name: 'Licorice / Anise',
    image: require('./images/flavors/licorice-anise.png'),
  },
  lime: {
    name: 'Lime',
    image: require('./images/flavors/lime.png'),
  },
  'lime-zest': {
    name: 'Lime Zest',
    image: require('./images/flavors/lime-zest.png'),
  },
  lychee: {
    name: 'Lychee',
    image: require('./images/flavors/lychee.png'),
  },
  mango: {
    name: 'Mango',
    image: require('./images/flavors/mango.png'),
  },
  marzipan: {
    name: 'Marzipan',
    image: require('./images/flavors/marzipan.png'),
  },
  melon: {
    name: 'Melon',
    image: require('./images/flavors/melon.png'),
  },
  mushrooms: {
    name: 'Mushrooms',
    image: require('./images/flavors/mushrooms.png'),
  },
  nutmeg: {
    name: 'Nutmeg',
    image: require('./images/flavors/nutmeg.png'),
  },
  oak: {
    name: 'Oak',
    image: require('./images/flavors/oak.png'),
  },
  orange: {
    name: 'Orange',
    image: require('./images/flavors/orange.png'),
  },
  'orange-zest': {
    name: 'Orange Zest',
    image: require('./images/flavors/orange-zest.png'),
  },
  'other-flowers': {
    name: 'Other Flowers',
    image: require('./images/flavors/other-flowers.png'),
  },
  'passion-fruit': {
    name: 'Passion Fruit',
    image: require('./images/flavors/passion-fruit.png'),
  },
  peach: {
    name: 'Peach',
    image: require('./images/flavors/peach.png'),
  },
  pear: {
    name: 'Pear',
    image: require('./images/flavors/pear.png'),
  },
  pineapple: {
    name: 'Pineapple',
    image: require('./images/flavors/pineapple.png'),
  },
  plum: {
    name: 'Plum',
    image: require('./images/flavors/plum.png'),
  },
  pomegranate: {
    name: 'Pomegranate',
    image: require('./images/flavors/pomegranate.png'),
  },
  raisins: {
    name: 'Raisins',
    image: require('./images/flavors/raisins.png'),
  },
  raspberry: {
    name: 'Raspberry',
    image: require('./images/flavors/raspberry.png'),
  },
  'red-apple': {
    name: 'Red Apple',
    image: require('./images/flavors/red-apple.png'),
  },
  'red-cherry': {
    name: 'Red Cherry',
    image: require('./images/flavors/red-cherry.png'),
  },
  'red-currant': {
    name: 'Red Currant',
    image: require('./images/flavors/red-currant.png'),
  },
  'red-pepper': {
    name: 'Red Pepper',
    image: require('./images/flavors/red-pepper.png'),
  },
  rhubarb: {
    name: 'Rhubarb',
    image: require('./images/flavors/rhubarb.png'),
  },
  'river-stones': {
    name: 'River Stones',
    image: require('./images/flavors/river-stones.png'),
  },
  rose: {
    name: 'Rose',
    image: require('./images/flavors/rose.png'),
  },
  sage: {
    name: 'Sage',
    image: require('./images/flavors/sage.png'),
  },
  salt: {
    name: 'Salt',
    image: require('./images/flavors/salt.png'),
  },
  slate: {
    name: 'Slate',
    image: require('./images/flavors/slate.png'),
  },
  smoke: {
    name: 'Smoke',
    image: require('./images/flavors/smoke.png'),
  },
  strawberry: {
    name: 'Strawberry',
    image: require('./images/flavors/strawberry.png'),
  },
  tar: {
    name: 'Tar',
    image: require('./images/flavors/tar.png'),
  },
  thyme: {
    name: 'Thyme',
    image: require('./images/flavors/thyme.png'),
  },
  'toast-brioche': {
    name: 'Toast / Brioche',
    image: require('./images/flavors/toast-brioche.png'),
  },
  tomato: {
    name: 'Tomato',
    image: require('./images/flavors/tomato.png'),
  },
  vanilla: {
    name: 'Vanilla',
    image: require('./images/flavors/vanilla.png'),
  },
  violet: {
    name: 'Violet',
    image: require('./images/flavors/violet.png'),
  },
  watermelon: {
    name: 'Watermelon',
    image: require('./images/flavors/watermelon.png'),
  },
  'white-pepper': {
    name: 'White Pepper',
    image: require('./images/flavors/white-pepper.png'),
  },
} satisfies { [key: string]: Omit<WineFlavor, 'id'> };

function createGroupWithFlavors(
  groupId: WineFlavorGroupKey,
  flavorIds: Array<WineFlavorKey>
): WineFlavorGroupWithFlavors {
  return {
    id: groupId,
    ...groupsMap[groupId],
    flavors: flavorIds.map((flavorId) => ({
      ...flavorsMap[flavorId],
      id: flavorId,
    })),
  };
}

// used for both sparkling-white and sparkling-rose
const sparklingFlavors = [
  createGroupWithFlavors('citrus', [
    'lemon',
    'lime',
    'grapefruit',
    'orange',
    'lemon-zest',
    'lime-zest',
    'grapefruit-zest',
    'orange-zest',
    'lemon-curd',
  ]),
  createGroupWithFlavors('orchard', [
    'green-apple',
    'golden-apple',
    'peach',
    'fig',
    'red-apple',
    'pear',
    'apricot',
    'honey',
    'caramel',
  ]),
  createGroupWithFlavors('berries-and-tropical-fruit', [
    'strawberry',
    'cranberry',
    'melon',
    'cotton-candy',
    'red-currant',
    'raspberry',
    'cantaloupe',
    'pineapple',
    'passion-fruit',
  ]),
  createGroupWithFlavors('floral-and-herbal', [
    'grass',
    'ginger',
    'flowers',
    'jasmine',
    'hay-straw',
    'lemongrass',
    'chamomile',
    'honeysuckle',
    'rose',
  ]),
  createGroupWithFlavors('baking-and-spices', [
    'vanilla',
    'butter',
    'almond',
    'toast-brioche',
    'cinnamon',
    'hazelnut',
    'marzipan',
    'biscuit',
    'creme-brulee',
  ]),
  createGroupWithFlavors('mineral-and-others', [
    'salt',
    'slate',
    'oak',
    'smoke',
    'chalk',
    'flint',
    'river-stones',
    'dirt-earth',
    'kerosene',
  ]),
];

export const flavors: {
  [key in Wine['type']]: Array<WineFlavorGroupWithFlavors>;
} = {
  white: [
    createGroupWithFlavors('citrus', [
      'lemon',
      'lime',
      'grapefruit',
      'orange',
      'lemon-zest',
      'lime-zest',
      'grapefruit-zest',
      'orange-zest',
      'caramel',
    ]),
    createGroupWithFlavors('orchard', [
      'green-apple',
      'golden-apple',
      'peach',
      'fig',
      'red-apple',
      'pear',
      'apricot',
      'honey',
      'raisins',
    ]),
    createGroupWithFlavors('tropical', [
      'melon',
      'passion-fruit',
      'banana',
      'mango',
      'cantaloupe',
      'guava',
      'lychee',
      'pineapple',
      'butterscotch',
    ]),
    createGroupWithFlavors('floral-and-herbal', [
      'grass',
      'thyme',
      'sage',
      'flowers',
      'hay-straw',
      'lemongrass',
      'green-olives',
      'honeysuckle',
      'almond',
    ]),
    createGroupWithFlavors('baking-and-spices', [
      'vanilla',
      'black-pepper',
      'cloves',
      'butter',
      'cinnamon',
      'white-pepper',
      'nutmeg',
      'ginger',
      'toast-brioche',
    ]),
    createGroupWithFlavors('mineral-and-others', [
      'salt',
      'slate',
      'oak',
      'smoke',
      'chalk',
      'flint',
      'river-stones',
      'cedar',
      'kerosene',
    ]),
  ],
  red: [
    createGroupWithFlavors('red-fruit', [
      'strawberry',
      'cranberry',
      'raspberry',
      'red-cherry',
      'red-currant',
      'tomato',
      'pomegranate',
      'chocolate',
      'caramel',
    ]),
    createGroupWithFlavors('dark-fruits', [
      'blueberry',
      'blackberry',
      'black-cherry',
      'plum',
      'black-currant',
      'raisins',
      'fig',
      'balsamic',
      'cola',
    ]),
    createGroupWithFlavors('other-fruits-and-floral', [
      'red-apple',
      'fig',
      'orange',
      'black-olive',
      'violet',
      'rose',
      'other-flowers',
      'green-pepper',
      'red-pepper',
    ]),
    createGroupWithFlavors('herbal', [
      'grass',
      'thyme',
      'sage',
      'lavender',
      'hay-straw',
      'mushrooms',
      'forest-floor',
      'eucalyptus',
      'black-tea',
    ]),
    createGroupWithFlavors('baking-and-spices', [
      'vanilla',
      'black-pepper',
      'cloves',
      'cocoa',
      'cinnamon',
      'white-pepper',
      'butter',
      'licorice-anise',
      'coffee',
    ]),
    createGroupWithFlavors('oak-smoke-and-other', [
      'dirt-earth',
      'bacon-game',
      'oak',
      'smoke',
      'graphite',
      'tar',
      'cedar',
      'cigar-tobacco',
      'leather',
    ]),
  ],
  rose: [
    createGroupWithFlavors('citrus', [
      'lemon',
      'lime',
      'grapefruit',
      'orange',
      'lemon-zest',
      'lime-zest',
      'grapefruit-zest',
      'orange-zest',
      'caramel',
    ]),
    createGroupWithFlavors('red-and-blue-fruit', [
      'strawberry',
      'cranberry',
      'raspberry',
      'red-cherry',
      'red-currant',
      'pomegranate',
      'blueberry',
      'black-cherry',
      'honey',
    ]),
    createGroupWithFlavors('summer', [
      'melon',
      'green-apple',
      'kiwi',
      'cotton-candy',
      'cantaloupe',
      'peach',
      'apricot',
      'pineapple',
      'watermelon',
    ]),
    createGroupWithFlavors('floral-and-herbal', [
      'grass',
      'flowers',
      'rose',
      'lavender',
      'lemongrass',
      'green-pepper',
      'rhubarb',
      'honeysuckle',
      'black-tea',
    ]),
    createGroupWithFlavors('baking-and-spices', [
      'vanilla',
      'black-pepper',
      'cloves',
      'butter',
      'cinnamon',
      'white-pepper',
      'cocoa',
      'marzipan',
      'toast-brioche',
    ]),
    createGroupWithFlavors('mineral-and-others', [
      'salt',
      'slate',
      'oak',
      'smoke',
      'chalk',
      'flint',
      'river-stones',
      'cedar',
      'dirt-earth',
    ]),
  ],
  'sparkling-white': sparklingFlavors,
  'sparkling-rose': sparklingFlavors,
};
